import { Link } from 'react-router-dom';
import { orderBy, isNil } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCategories } from 'stores';
import { Row, Skeleton } from 'components';
import { recommendedWrapper, categoriesCardsWrapper, categoryCardStyle, categoriesWrapperRow } from './styles';

export const RecommendedCategories = () => {
  const categories = useCategories();
  const { getText } = useTranslations();

  return (
    <div css={recommendedWrapper}>
      <Row container>
        <h2 className="section-title">{getText('recommendedCategories')}</h2>
      </Row>
      <Row container css={categoriesWrapperRow}>
        {isNil(categories)
          ? Array(3)
              .fill()
              .map((_, index) => (
                <div key={index} css={categoriesCardsWrapper}>
                  <Skeleton height="180px" width="100%" />
                </div>
              ))
          : orderBy(categories, 'name')?.map((category) => (
              <Link to={`/courses?categoryId=${category?.id}`} css={categoriesCardsWrapper} key={category?.id}>
                <div css={categoryCardStyle}>
                  <img src={category?.iconUrl} alt={getText(category?.name)} />
                  <h5>{getText(category?.name)}</h5>
                </div>
              </Link>
            ))}
      </Row>
    </div>
  );
};
