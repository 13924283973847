export const coursePreviewContainer = {
  display: 'flex',
  flexFlow: 'row nowrap',

  '& .course-preview-container': {
    flex: '1 0 0',

    '& .course-player-container': {
      minHeight: '25rem',
      maxHeight: 'calc(100vh - 18rem)',
      paddingTop: '56.25%',
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#000',
    },
  },
  '@media (max-width: 768px)': {
    display: 'flex',
    flexDirection: 'column',
  },
};

export const loader = {
  backgroundColor: 'black',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
