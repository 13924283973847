export const carouselCardWrapper = {
  width: '100%',
  display: 'flex',
  margin: '0 -15px',
  padding: '3em 0',
  '@media (max-width: 768px)': {
    flexWrap: 'wrap-reverse',
  },
};

export const carouselCardTextWrapper = (theme) => ({
  flex: '0 0 50%',
  padding: '0 15px',
  '@media (max-width: 768px)': {
    flex: '0 0 100%',
    marginBottom: '1em',
  },
  '& .course-category': {
    color: theme.purpleLight,
    marginBottom: '0.75em',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  '& .course-heading': {
    color: theme.white,
    marginBottom: '0.75em',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    lineHeight: '2.25rem',
    fontSize: '1.5rem',
  },
  '& .course-description': {
    marginBottom: '3em',
    color: theme.white2,
    opacity: '80%',
    lineHeight: '1.5',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& .btn-and-icons': {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5em',
  },
  '& .icon-and-text': {
    color: theme.backgroundLight,
    opacity: '80%',
  },
});

export const carouselCardImgWrapper = {
  flex: '0 0 50%',
  padding: '0 15px',
  textAlign: 'right',
  '@media (max-width: 768px)': {
    flex: '0 0 100%',
  },
};

export const carouselImgSpan = (theme) => ({
  position: 'absolute',
  top: '55px',
  right: '37px',
  backgroundColor: theme.purpleDark,
  color: theme.white,
  textTransform: 'uppercase',
  padding: '0.25em',
  fontSize: '0.625rem',
  fontWeight: 600,
});

export const responsiveImg = {
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '200px',
  objectFit: 'cover',
};

export const buttonStyle = (theme) => ({
  padding: '0.5em 0.75em',
  color: theme.white,
  backgroundColor: theme.yellow,
  borderRadius: 4,
  fontSize: '1rem',
  lineHeight: '1.1375rem',
  '&:hover': {
    backgroundColor: theme.warning,
  },
});
