import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isNull, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row, Col, Button, Icon, Tooltip, Skeleton, showApiError, DefaultPagination } from 'components';
import { useAuth } from '../../../components/UI/Authenticate';
import { getAllFriends } from '../../../services/accounts';
import avatarImg from '../../../assets/images/default-avatar.png';
import packageImg from '../../../assets/images/starter.png';
import {
  wrapper,
  headerWrap,
  heading,
  tableWrap,
  numberWrap,
  badgeStyle,
  packagesTooltipWrap,
  packageCircle,
  footerWrap,
  noData,
  backBtn,
} from './styles';

const PAGE_SIZE = 5;

const FriendsRankList = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const [friends, setFriends] = useState(null);
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();
  const pageIndexRef = useRef(0);

  useEffect(() => {
    fetchMyFriends();
  }, [currentAccount?.id]);

  const fetchMyFriends = async (pageIndex) => {
    const [res, err] = await getAllFriends(accountId, {
      includeDetails: true,
      pageIndex: pageIndex ?? 0,
      pageSize: PAGE_SIZE,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setFriends(res ?? {});
  };

  const handlePageChange = (pageIndex) => {
    pageIndexRef.current = pageIndex;
    fetchMyFriends(pageIndex);
  };

  return (
    <div css={wrapper}>
      <div css={headerWrap}>
        <Row horizontalGap={12} noWrap container>
          <Button clear onClick={() => history.goBack()} css={backBtn}>
            <Icon iconName="las la-arrow-circle-left" />
            {getText('back')}
          </Button>
          <h1 css={heading}>{getText('friendsRankList')}</h1>
        </Row>
      </div>
      <Row horizontalGap={12} noWrap container css={tableWrap}>
        <Col xs={12}>
          <Row className="tr" horizontalGap={12}>
            <Col md={4}>
              <p className="th">{getText('name')}</p>
            </Col>
            <Col md={1}>
              <p className="th">{getText('points')}</p>
            </Col>
            <Col md={3}>
              <p className="th">{getText('badges')}</p>
            </Col>
            <Col md={3}>
              <p className="th">{getText('products')}</p>
            </Col>
          </Row>
          {!isNull(friends) ? (
            !isEmpty(friends?.data) ? (
              friends?.data?.map((el, i) => (
                <Row className="cell-row" horizontalGap={12} align="center" key={el.externalAccountId}>
                  <Col md={4}>
                    <div className="info-cell">
                      <div css={numberWrap(false)}>
                        <p className="number">{PAGE_SIZE * pageIndexRef.current + i + 1}</p>
                      </div>
                      <img src={el?.pictureUrl ?? avatarImg} className="avatar" alt="course-card-logo" />
                      <div className="friend-info">
                        <h3>
                          {el?.firstName} {el?.lastName}
                        </h3>
                        <p>{el?.nickName}</p>
                      </div>
                    </div>
                  </Col>
                  <Col md={1}>
                    <div className="points-cell">
                      <p>{el?.accomplishment?.points ?? 0}</p>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="badge-cell">
                      {!isEmpty(el?.accomplishment?.badges) &&
                        el?.accomplishment?.badges?.map((badge) => (
                          <Tooltip position="top" content={<p>{getText(badge?.name)}</p>} key={badge.id}>
                            <div className="badge-wrap">
                              <div css={badgeStyle(badge?.pictureUrl)} />
                            </div>
                          </Tooltip>
                        ))}
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="packages-cell">
                      {el?.externalAccount?.packages?.map(
                        (item, index) =>
                          (index <= 1 || el?.externalAccount?.packages?.length === 3) && (
                            <Tooltip
                              position="top"
                              content={<p>{getText(item?.name)}</p>}
                              key={`${item?.catalogueItemId}-${index}`}>
                              <div className="package-wrap">
                                <div css={packageCircle(item?.picThumbnailUrl ?? packageImg)} />
                              </div>
                            </Tooltip>
                          ),
                      )}
                      {el?.externalAccount?.packages?.length > 3 && (
                        <Tooltip
                          position="top"
                          content={
                            <div css={packagesTooltipWrap(Math.min(el?.externalAccount?.packages?.length - 2, 3))}>
                              {el?.externalAccount?.packages?.map(
                                (item, index) =>
                                  index > 1 && (
                                    <div className="package-tooltip-wrap" key={`${item?.catalogueItemId}-${index}`}>
                                      <div css={packageCircle(item?.picThumbnailUrl ?? packageImg)} />
                                      <p>{getText(item?.name)}</p>
                                    </div>
                                  ),
                              )}
                            </div>
                          }>
                          <div className="package-wrap">
                            <div css={packageCircle(null)}>+{el?.externalAccount?.packages?.length - 2}</div>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </Col>
                </Row>
              ))
            ) : (
              <div css={noData}>
                <h2>{getText('noFriendsFound')}</h2>
              </div>
            )
          ) : (
            <Skeleton height="50px" width="100%" />
          )}
        </Col>
      </Row>
      {friends?.data && !isEmpty(friends?.data) && (
        <div css={footerWrap}>
          <DefaultPagination
            totalResults={friends?.total}
            pageIndex={0}
            pageSize={PAGE_SIZE}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default FriendsRankList;
