import { useTranslations } from '@veraio/strank';
import { Row, Icon } from 'components';
import { durationInMinutes } from 'utils';
import { COURSE_STEP_TYPE_ICON, COURSE_STEP_TYPE } from 'enum';
import { curriculumWrapper } from './styles';

const CourseDetailsCurriculum = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <Row container>
      <div css={curriculumWrapper}>
        <h2>{getText('courseCurriculum')}</h2>
        <ul>
          {course?.steps?.map((el) => (
            <li key={el.id} className="curriculum-item">
              <div className="curriculum-item-title-container">
                <span className="curriculum-item-icon">
                  <Icon iconName={COURSE_STEP_TYPE_ICON[el.type]} />
                </span>
                {getText(el.title)}
              </div>
              {el.type === COURSE_STEP_TYPE.VIDEO && (
                <span className="item-duration">{durationInMinutes(el.duration)}</span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Row>
  );
};

export default CourseDetailsCurriculum;
