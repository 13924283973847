import { useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { Row, Col, Skeleton, useAuth, CourseCategoryCard } from 'components';
import { useCourses, getRandomCourses } from 'stores';
import { wrapper, recommendedSeeAllBtn, customArrowStyle, titleAndArrows } from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export const RecommendedCourses = () => {
  const { user } = useAuth();
  const { getText } = useTranslations();
  const carouselRef = useRef();
  useCourses();

  const courses = getRandomCourses();
  const { profile } = user;

  const CustomArrow = ({ onClick, direction }) => (
    <button onClick={onClick} css={customArrowStyle}>
      {direction === 'left' ? <i className="fas fa-chevron-left" /> : <i className="fas fa-chevron-right" />}
    </button>
  );

  return (
    <section css={wrapper}>
      <div css={titleAndArrows}>
        <h2 className="section-title">{`${getText('recommendedCoursesForYou')}, ${profile?.firstName}`}</h2>
        {!!courses && (
          <div className="arrows-container">
            <CustomArrow direction="left" onClick={() => carouselRef.current.previous()} />
            <CustomArrow direction="right" onClick={() => carouselRef.current.next()} />
          </div>
        )}
      </div>
      {!isNil(courses) ? (
        <Row container>
          <Col>
            <Carousel
              ref={(ref) => {
                carouselRef.current = ref;
              }}
              infinite
              autoPlay
              arrows={false}
              autoPlaySpeed={3000}
              responsive={responsive}
              containerClass="carousel-container">
              {courses.map((course) => (
                <div key={course.id} css={{ padding: '0 0.5em' }}>
                  <CourseCategoryCard course={course} />
                </div>
              ))}
            </Carousel>
            <Link to={'/courses'} css={recommendedSeeAllBtn}>
              {getText('viewAll')}
            </Link>
          </Col>
        </Row>
      ) : (
        <Row horizontalGap={12} container>
          <Col sm={6} className="bottomBuffer">
            <Skeleton height="300px" width="100%" />
          </Col>
          <Col sm={6} className="bottomBuffer">
            <Skeleton height="300px" width="100%" />
          </Col>
        </Row>
      )}
    </section>
  );
};

RecommendedCourses.propTypes = {
  categoryId: PropTypes.any,
};
