import { Link, useLocation } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { useAuth } from '../Authenticate';
import { Row } from '../Grid';
import Icon from '../Icon';
import LanguageDropdown from '../LanguageDropdown';
import logo from 'assets/images/logo.svg';
import FooterCard from './FooterCard';
import { icon, productElements } from './definitions';
import {
  footerContainer,
  footerDescription,
  usernameBranchContainer,
  appAvatarIcon,
  descriptionStyles,
  iconsContainer,
  footerColumn,
  columnTitle,
  footerLink,
  unAuthenticatedFooterStyles,
  unAuthenticatedFooterRowStyles,
  authenticatedFooter,
  footerLeft,
  footerRight,
  footerLinks,
  rowWrapperNoWrap,
  logoAndDescription,
} from './styles';

const Footer = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { getText } = useTranslations();
  const currentYear = new Date().getFullYear();

  return (
    <footer css={footerContainer}>
      {isAuthenticated && (
        <Row container noWrap css={authenticatedFooter}>
          <div css={logoAndDescription}>
            <Link to="/" css={usernameBranchContainer}>
              <img src={logo} css={appAvatarIcon} alt="Logo" />
            </Link>
            <p css={descriptionStyles}>{getText('footerText')}</p>
          </div>
          <div css={footerDescription}>
            <div css={rowWrapperNoWrap}>
              <div css={footerColumn}>
                <h5 css={columnTitle}>{getText('courses')}</h5>
                {productElements(getText).map((el, index) => (
                  <Link key={index} to={el.path} css={footerLink(el.path === location.pathname)}>
                    {el.title}
                  </Link>
                ))}
              </div>
              <FooterCard />
            </div>
          </div>
        </Row>
      )}
      <div css={unAuthenticatedFooterStyles}>
        <Row container noWrap css={unAuthenticatedFooterRowStyles}>
          <div css={footerLeft}>
            <p>
              © {currentYear} - One Academy. {getText('allRightReserved')}
            </p>
            <Link to="/terms-and-conditions" css={footerLinks}>
              {getText('termsConditions')}
            </Link>
            <Link to="/privacy-policy" css={footerLinks}>
              {getText('privacyPolicy')}
            </Link>
          </div>
          <div css={footerRight}>
            <div css={iconsContainer}>
              {icon.map((el, index) => (
                <a href={el.path} key={index} className="links" target="_blank" rel="noopener noreferrer">
                  <Icon iconName={el.icon} size={20} />
                </a>
              ))}
            </div>
            <LanguageDropdown />
          </div>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
