import { Link, useLocation } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import UserDropdown from './UserDropdown';
import { useAuth } from '../Authenticate';
import { privateLinks } from './definitions';
import { sidesContainer, linkStyles, navContainer } from './styles';

const NavLinks = () => {
  const { getText } = useTranslations();
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return (
    <div css={navContainer}>
      <div css={sidesContainer}>
        {isAuthenticated &&
          privateLinks(getText).left.map((el) => {
            const isActive = location.pathname === el.path;
            return (
              <Link to={el.path} key={el.path} css={linkStyles(true, isActive)}>
                {el.label}
              </Link>
            );
          })}
      </div>
      <div css={sidesContainer}>
        {isAuthenticated && (
          <>
            {privateLinks(getText).right.map((el) => {
              const isActive = location.pathname === el.path;
              return (
                <Link to={el.path} key={el.path} css={linkStyles(true, isActive)}>
                  {el?.icon && <img src={el.icon} alt="" />}
                  {el.label}
                </Link>
              );
            })}
            <UserDropdown />
          </>
        )}
      </div>
    </div>
  );
};

export default NavLinks;
