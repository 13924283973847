import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import ReactPlayer from 'react-player/vimeo';
import { showApiError } from 'components';
import { markStepAsCompleted } from 'stores';
import { updateAccountCourse } from 'services';

export const VideoViewer = ({ currentStep, course, onNextStep, onReady }) => {
  const language = useTranslations((translationsState) => translationsState?.language);
  const matchTranslation = currentStep?.translations?.find((el) => el.languageCode === language?.code);
  const videoUrl = matchTranslation?.sourceUrl ?? currentStep?.sourceUrl;

  const handleCourseProgressUpdate = async () => {
    const [, err] = await updateAccountCourse(course?.accountCourse?.id, {
      accountId: course?.accountCourse?.accountId,
      courseId: course?.id,
      completedLessonId: currentStep?.id,
    });

    if (err) return showApiError(err);

    markStepAsCompleted(course, currentStep?.id);

    // go to the next step after 3 seconds
    setTimeout(() => {
      onNextStep();
    }, 1800);
  };

  return (
    !!language?.code && (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <ReactPlayer
          key={`${videoUrl}${language?.code}`}
          controls
          stopOnUnmount={false}
          url={videoUrl}
          width="100%"
          height="100%"
          config={{ vimeo: { playerOptions: { autoplay: true, texttrack: language?.code } } }}
          onStart={onReady}
          onEnded={handleCourseProgressUpdate}
        />
      </div>
    )
  );
};

VideoViewer.propTypes = {
  currentStep: PropTypes.object,
  course: PropTypes.object,
  onNextStep: PropTypes.func,
  onReady: PropTypes.func,
};
