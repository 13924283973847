import { createStore, uniq, useStore } from '@veraio/core';
import { showApiError } from 'components';
import { getMyCourses } from 'services';

const initState = null;

const myCoursesStore = createStore(initState);

export const initMyCoursesStore = async (accountId) => {
  const [res, err] = await getMyCourses({ accountId });
  if (err) return showApiError(err);

  const mappedCourses = res?.data?.map((el) => {
    const completedLessonsLength = el.accountCourse?.completedLessonIds?.length ?? 0;
    const isCompleted = completedLessonsLength === el.courseLessonsCount;

    return {
      ...el,
      isCompleted,
      inProgress: !!completedLessonsLength && !isCompleted,
    };
  });

  myCoursesStore.setState(mappedCourses);
};

export const markStepAsCompleted = (course, lessonId) => {
  const modifyCourse = (el) => {
    const newCompletedLessons = uniq([...(el?.accountCourse?.completedLessonIds ?? []), lessonId]);
    const isCompleted = newCompletedLessons?.length === course?.courseLessonsCount;

    return {
      ...el,
      inProgress: !isCompleted,
      isCompleted,
      accountCourse: {
        ...el?.accountCourse,
        completedLessonIds: newCompletedLessons,
        completedLessonProgress: Math.floor((newCompletedLessons.length / course?.courseLessonsCount) * 100),
      },
    };
  };

  const mappedCourses = myCoursesStore?.getState()?.map((el) => (el?.id === course?.id ? modifyCourse(el) : el));

  myCoursesStore.setState(mappedCourses);
};

export const useMyCourses = (callback) => useStore(myCoursesStore, callback);
