import { useRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { ReactComponent as SearchIcn } from '../../../assets/images/searchInputIcn.svg';
import { wrapper } from './styles';

const SearchInput = (props) => {
  const { value, placeholder, name, onChange, noMinLength, clearOnSearch } = props;
  const inputRef = useRef('');

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 13) {
      isFunction(onChange) &&
        onChange(inputRef.current?.value?.length < 3 && !noMinLength ? '' : inputRef.current?.value);

      if (clearOnSearch) inputRef.current.value = '';
    }
  };

  return (
    <div css={wrapper} className="search-input-container">
      <input
        ref={inputRef}
        className="search-input"
        type="text"
        placeholder={placeholder}
        name={name}
        onKeyDown={handleKeyDown}
        defaultValue={value ?? ''}
      />
      <div className="search-input-icon">
        <SearchIcn />
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  noMinLength: PropTypes.bool,
  clearOnSearch: PropTypes.bool,
};

export default SearchInput;
