import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components';
import { durationInMinutes } from 'utils';
import {
  carouselCardWrapper,
  carouselCardTextWrapper,
  carouselCardImgWrapper,
  carouselImgSpan,
  responsiveImg,
  buttonStyle,
} from './styles';

const CarouselCard = ({ title, category, intro, lessons, totalVideoDurationInSeconds, image, isFeatured, id }) => {
  const { getText } = useTranslations();

  return (
    <div css={carouselCardWrapper}>
      <div css={carouselCardTextWrapper}>
        <h5 className="course-category">{getText(category)}</h5>
        <h3 className="course-heading">{getText(title)}</h3>
        <p className="course-description">{getText(intro)}</p>
        <div className="btn-and-icons">
          <Link to={`/course-details/${id}`} css={buttonStyle}>
            {getText('viewCourse')}
          </Link>
          <div className="icon-and-text">
            <Icon iconName="la la-file" /> {`${lessons} ${getText('lessons')}`}
          </div>
          <div className="icon-and-text">
            <Icon iconName="la la-clock" /> {durationInMinutes(totalVideoDurationInSeconds) ?? 'N/A'}
          </div>
        </div>
      </div>
      <div css={carouselCardImgWrapper}>
        <img src={image} alt="carousel-img" css={responsiveImg} />
        {isFeatured && <span css={carouselImgSpan}>{getText('featuredCourse')}</span>}
      </div>
    </div>
  );
};

CarouselCard.propTypes = {
  isFeatured: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  intro: PropTypes.string,
  lessons: PropTypes.number,
  totalVideoDurationInSeconds: PropTypes.number,
  image: PropTypes.string,
  id: PropTypes.number,
};

export default CarouselCard;
