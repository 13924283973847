import { useTranslations } from '@veraio/strank';
import { Row, Button, Icon } from 'components';
import { howDoesItWorkWrapper, backBtn } from './styles';

const HowDoesItWork = ({ history }) => {
  const { getText } = useTranslations();

  return (
    <section css={howDoesItWorkWrapper}>
      <Row container>
        <Button clear onClick={() => history.goBack()} css={backBtn}>
          <Icon iconName="las la-arrow-circle-left" />
          {getText('back')}
        </Button>
        <div>{getText('achievementsHowItWork')}</div>
      </Row>
    </section>
  );
};

export default HowDoesItWork;
