import { getReq } from './axios/makeRequest';
import { getAllCategoriesParams } from './models/categories';

const baseUrl = `${apiUrls.oneAcademy}/categories`;

export const getAllCategories = async (options = {}) => {
  const params = getAllCategoriesParams(options);

  const [res, err] = await getReq(`${baseUrl}/list?${params}`);

  return [res, err];
};
