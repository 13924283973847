import PropTypes from 'prop-types';
import { skeletonClass } from './styles';

const Skeleton = (props) => {
  const { count = 1 } = props;

  const elements = [];

  for (let i = 0; i < count; i++) {
    elements.push(
      <span key={i} css={skeletonClass(props)}>
        &zwnj;
      </span>,
    );
  }

  return elements;
};

Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
};

export default Skeleton;
