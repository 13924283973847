import { useEffect } from 'react';
import { initTranslationsStore, getBrowserLanguage } from '@veraio/strank';
import { Switch, Redirect } from 'react-router-dom';
import { initLocationsStore, useLocations } from '@oneecosystem/dealshaker-core';
import { useTranslations, getSavedLanguage, initLanguagesStore } from '@veraio/strank';
import { useAuth, NavBar, Footer, Notifications, PageLoader } from 'components';
import { useDeepEffect } from 'hooks';
import { initAchievementsStore, initCategoriesStore, initCoursesStore, initMyCoursesStore } from 'stores';
import PrivateRoutes from '../Private/Routes';
import PublicRoutes from '../Public/Routes';
import { contentContainer, applicationContainer } from './styles';

const App = () => {
  const { isAuthenticated, user } = useAuth();
  const { changeLanguage, allEnvLanguages } = useTranslations();
  const geoLocation = useLocations((locationsState) => locationsState.geoLocation);

  const { academyAccount, profile, currentAccount } = user ?? {};
  const { id: accountId } = academyAccount ?? {};
  const isActive = profile?.status?.toLowerCase() === 'active' && currentAccount?.status?.toLowerCase() === 'active';

  useEffect(() => {
    initLocationsStore();
    initTranslationsStore();
    initLanguagesStore();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      initCategoriesStore();
      initCoursesStore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      initAchievementsStore(accountId);
      initMyCoursesStore(accountId);
    }
  }, [isAuthenticated, accountId]);

  useDeepEffect(() => {
    geoLocation?.languageCode &&
      allEnvLanguages?.length &&
      changeLanguage(getSavedLanguage() ?? geoLocation.languageCode ?? getBrowserLanguage());
  }, [allEnvLanguages, geoLocation]);

  return (
    <main css={applicationContainer}>
      <Notifications />
      <PageLoader />
      {isAuthenticated && <NavBar />}
      <section css={contentContainer}>
        <Switch>
          {isAuthenticated ? PrivateRoutes(isActive) : PublicRoutes}
          <Redirect to={isAuthenticated ? '/dashboard' : '/login'} />
        </Switch>
      </section>
      <Footer />
    </main>
  );
};

export default App;
