export const pageWrapper = (theme) => ({
  backgroundColor: theme.primary,
  padding: '1em',
  flex: 1,
});

export const headingStyles = (theme) => ({
  fontSize: '2rem',
  color: theme.white,
  marginBottom: '0.75em',
  fontWeight: 600,
});

export const subHeadingAchievements = (theme) => ({
  fontSize: '1rem',
  color: theme.white,
  marginBottom: '1em',
  fontWeight: 400,
});

export const knowWorldWrapper = {
  display: 'flex',
  gap: '0.5em',
  height: '2.5rem',
  alignItems: 'baseline',
  '@media (max-width: 450px)': {
    flexDirection: 'column',
    marginBottom: '2em',
  },
};

export const rowWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '2em 0',
};

export const learnEarnWrapper = (theme) => ({
  paddingLeft: '2em',
  backgroundColor: theme.primaryLighter,
  color: theme.white,
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  gap: '1em',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

export const learnEarnTextWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1em',
  alignItems: 'flex-start',
};

export const learEarnTitle = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.25rem',
  fontWeight: 400,
};

export const learEarnText = {
  fontFamily: 'Roboto, sans-serif;',
  fontWeight: 400,
  fontSize: '0.75rem',
};

export const learnEarnButton = (theme) => ({
  flex: '0 0 auto',
  backgroundColor: theme.primaryLighter2,
  borderRadius: 34,
  border: 'none',
  padding: '0.5em 0.75em',
  '&:hover': {
    backgroundColor: theme.yellow,
  },
});

export const responsiveImg = {
  width: '100%',
  height: 'auto',
};

export const levelWrapper = (theme) => ({
  color: theme.white,
  justifyContent: 'space-between',
  gap: '1em',
  margin: '3em 0',
});

export const leftSide = (theme) => ({
  color: theme.white,
  flex: 2,
});

export const rightSide = (theme) => ({
  color: theme.white,
  flex: 1,
});

export const cardsWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-0.5em',
  '@media (max-width: 500px)': {
    flexDirection: 'column',
    '& h4, p': {
      textAlign: 'center',
    },
  },
};

export const cardWrapper = {
  flex: '1 0 0%',
  width: '100%',
  maxWidth: '100%',
  padding: '0.5em',
};
export const learnEarnCard = (theme) => ({
  backgroundColor: theme.primaryLighter,
  padding: '1em',
});

export const medalIcons = {
  width: '30px',
  height: '30px',
};
export const achievementPoints = {
  fontSize: '2.5rem',
  fontWeight: 500,
  fontFamily: 'Poppins, sans-serif',
};

export const pointsEarned = {
  fontFamily: 'Roboto, sans-serif;',
  fontWeight: 400,
  fontSize: '0.75rem',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
};

export const yourBadgesWrapper = {
  margin: '2em 0',
};

export const yourBadgesTitle = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '0.875rem',
  marginBottom: '1.5em',
};

export const badgeWrapperRow = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '-0.5em',
};

export const badgeWrapper = {
  flex: '0 0 0%',
  width: '100%',
  maxWidth: '100%',
  padding: '0.5em',
};

export const badgeContent = (theme) => ({
  border: `1px solid ${theme.purpleLight}`,
  padding: '1em',
  textAlign: 'center',
  borderRadius: 4,

  '& .badge-image': {
    marginBottom: '0.5em',
    maxWidth: '9rem',
  },

  '& .badge-name': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    textTransform: 'capitalize',
    marginBottom: '0.5em',
  },

  '& .badge-earned-on': {
    fontFamily: 'Roboto, sans-serif;',
    fontWeight: 400,
    fontSize: '0.75rem',
  },
});

export const noBadgesStyles = {
  padding: '0 0.5em',
  fontSize: '0.875rem',
};
