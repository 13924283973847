export const detailsDescription = (theme) => ({
  width: '100%',
  marginBottom: '3em',
  '& h2': {
    color: theme.primary,
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    marginBottom: '1em',
  },
  '@media (max-width: 575px)': {
    padding: '0 1.5em',
  },
});

export const whatYourWillLearn = (theme) => ({
  padding: '2em',
  backgroundColor: theme.gray5,
  margin: '3em 0',
  borderRadius: 8,
  width: '100%',
  '& .items-wrapper': {
    display: 'flex',
    gap: '1em',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  '& .check-and-text': {
    flex: '0 0 45%',
    display: 'flex',
    gap: '1em',
    alignItems: 'center',
    marginBottom: '1em',
  },
  '& .fa-check-circle': {
    color: theme.purpleDark,
    fontSize: '1.5rem',
  },
  '@media (max-width: 575px)': {
    padding: '1.5em',
    '& .items-wrapper': {
      flexDirection: 'column',
    },
  },
});

export const learnTitle = {
  marginTop: 0,
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
};
