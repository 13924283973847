export const certificateTabWrapper = {
  '& h6': {
    marginBottom: '1em',
  },
  '& .pdf-preview-container': {
    width: '300px',
    height: '212px',
    backgroundColor: '#fff',
    border: 'none',
    maxHeight: '53vw',

    '@media (max-width: 400px)': {
      maxHeight: '49vw',
      maxWidth: '70vw',
    },
  },
};

export const certificateBtn = {
  padding: 0,
  position: 'relative',
  '& .overlay': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'zoom-in',
  },
};

export const modalStyles = (theme) => ({
  top: 60,
  '& section': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '786px',
  },
  '& .pdf-preview-modal': {
    width: '792px',
    height: '560px',
    backgroundColor: theme.white,
    border: 'none',
    maxHeight: '53vw',
    position: 'relative',
    '@media (max-width: 1000px)': {
      maxHeight: '49vw',
      maxWidth: '70vw',
    },
  },

  '& .close-btn': {
    position: 'absolute',
    top: 4,
    right: '0',
    background: 'transparent',
    border: 'none',
    fontSize: '2rem',
    color: theme.newGray0,
    cursor: 'pointer',
    zIndex: 10000,
    lineHeight: 1,
    '& i': {
      margin: 0,
    },
  },

  '& .downloadBtn-wrapper': {
    background: theme.backgroundLight,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '1em 0',
    '@media (max-width: 1000px)': {
      maxHeight: '70vw',
      maxWidth: '70vw',
    },
  },
  '& button': {
    alignItems: 'baseline !important',
  },
  '& img': {
    width: 16,
    height: 16,
    marginRight: '0.5em',
  },
});
