import { useTranslations } from '@veraio/strank';
import { Link } from 'react-router-dom';
import { useAchievements } from 'stores';
import { Row, useAuth, FriendsRankList } from 'components';
import learnAndEarnImg from 'assets/images/learnAndEarnImg.svg';
import achievementsMedal from 'assets/images/achievements-medal.svg';
import courseComplete from 'assets/images/coursesCompletedCheck.svg';
import quizesCompleted from 'assets/images/quizesCompletedCheck.svg';
import {
  rowWrapper,
  learnEarnWrapper,
  headingStyles,
  subHeadingAchievements,
  learnEarnTextWrapper,
  learEarnTitle,
  learEarnText,
  learnEarnButton,
  responsiveImg,
  levelWrapper,
  leftSide,
  rightSide,
  cardsWrapper,
  cardWrapper,
  learnEarnCard,
  medalIcons,
  achievementPoints,
  pointsEarned,
  yourBadgesWrapper,
  yourBadgesTitle,
  badgeContent,
  badgeWrapper,
  badgeWrapperRow,
  pageWrapper,
  noBadgesStyles,
} from './styles';

const Achievements = () => {
  const achievements = useAchievements();
  const { getText } = useTranslations();
  const {
    user: { profile },
  } = useAuth();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  return (
    <div css={pageWrapper}>
      <Row container css={rowWrapper}>
        <div>
          <h3 css={headingStyles}>{getText('yourAchievements')}</h3>
          <h6 css={subHeadingAchievements}>{`${getText('hey')}, ${profile?.firstName}. ${getText(
            'achievementsSoFar',
          )}.`}</h6>
        </div>
      </Row>
      <Row container css={learnEarnWrapper}>
        <div css={learnEarnTextWrapper}>
          <h5 css={learEarnTitle}>{getText('learnAndEarn')}</h5>
          <p css={learEarnText}>{getText('achievementsBadges')}</p>
          <Link to="/how-does-it-work" css={learnEarnButton}>
            {getText('howItWork')}
          </Link>
        </div>
        <div>
          <img src={learnAndEarnImg} alt="learn-earn-img" css={responsiveImg} />
        </div>
      </Row>
      <Row container css={levelWrapper}>
        <div css={leftSide}>
          <div css={cardsWrapper}>
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={achievementsMedal} alt="achievement-medal" css={medalIcons} />
                <h4 css={achievementPoints}>{achievements?.points}</h4>
                <p css={pointsEarned}>{getText('totalPointsEarned')}</p>
              </div>
            </div>
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={courseComplete} alt="course-complete" css={medalIcons} />
                <h4 css={achievementPoints}>{achievements?.completedCoursesCount}</h4>
                <p css={pointsEarned}>{getText('coursesCompleted')}</p>
              </div>
            </div>
            <div css={cardWrapper}>
              <div className="learn-earn-card" css={learnEarnCard}>
                <img src={quizesCompleted} alt="quizes-completed" css={medalIcons} />
                <h4 css={achievementPoints}>{achievements?.totalQuizTakes}</h4>
                <p css={pointsEarned}>{getText('quizesCompleted')}</p>
              </div>
            </div>
          </div>
          <div className="badges-wrapper" css={yourBadgesWrapper}>
            <p css={yourBadgesTitle}>{getText('yourBadges')}</p>
            <div css={badgeWrapperRow}>
              {achievements?.badges?.length > 0 ? (
                achievements?.badges.map((badge) => (
                  <div key={badge?.id} css={badgeWrapper}>
                    <div css={badgeContent}>
                      <img className="badge-image" src={badge?.pictureUrl} alt={badge?.name} />
                      <h6 className="badge-name">{getText(badge?.name)}</h6>
                      <p className="badge-earned-on">{`${getText('earnedOn')} - ${formatDate(badge?.createdOn)}`}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p css={noBadgesStyles}>{getText('noBadgesYet')}</p>
              )}
            </div>
          </div>
        </div>
        <div css={rightSide}>
          <FriendsRankList />
        </div>
      </Row>
    </div>
  );
};

export default Achievements;
