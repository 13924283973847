import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Row, Button, Icon } from 'components';
import { pageWrapper, backBtn } from './styles';

const TermsAndConditions = () => {
  const history = useHistory();
  const { getText } = useTranslations();

  return (
    <section css={pageWrapper}>
      <Row container>
        <Button clear onClick={() => history.goBack()} css={backBtn}>
          <Icon iconName="las la-arrow-circle-left" />
          {getText('back')}
        </Button>
      </Row>
      <Row container className="content">
        {getText('oneAcademyTermsTextPage')}
      </Row>
    </section>
  );
};

export default TermsAndConditions;
