import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, showApiError } from 'components';
import { useDeepEffect } from 'hooks';
import { getCourseById, quizResults, postNewAccountCourse } from 'services';
import { uniq } from '@veraio/core';
import { initMyCoursesStore } from 'stores';
import { COURSE_STEP_TYPE } from 'enum';

export const useCourse = () => {
  const { id: courseId } = useParams();
  const { user } = useAuth();
  const [course, setCourse] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [quizResultsPercentage, setQuizResultsPercentage] = useState(null);

  const currentStepIndex = course?.steps?.findIndex((el) => el.id === currentStep.id);
  const hasNextStep = currentStepIndex < course?.steps?.length - 1;
  const hasPreviousStep = currentStepIndex > 0;
  const { academyAccount } = user;

  useDeepEffect(() => {
    getCourse();
  }, [courseId, user]);

  useEffect(() => {
    course?.id && academyAccount?.id && fetchQuizResults();
  }, [course?.id, academyAccount?.id]);

  const getCourse = async () => {
    const [res, err] = await getCourseById(courseId, user?.academyAccount?.id);
    if (err) return showApiError(err);

    let accountCourse = res?.accountCourse;

    if (!res?.accountCourse) {
      const [resNewAccount, errNewAccount] = await postNewAccountCourse({
        courseId,
        accountId: user?.academyAccount?.id,
      });

      if (resNewAccount) {
        accountCourse = resNewAccount;
        initMyCoursesStore(user?.academyAccount?.id);
      } else errNewAccount && showApiError(errNewAccount);
    }

    setCourse({ ...res, accountCourse });
    setCurrentStep(res?.steps?.at(0));
  };

  const fetchQuizResults = async () => {
    const [res, err] = await quizResults({ accountId: academyAccount?.id, courseId: course?.id });

    !err && res?.length > 0 ? setQuizResultsPercentage(res[0].scorePercentage) : setQuizResultsPercentage(0);
  };

  // This method is used by the curriculum because it has all steps
  const handleStepChange = (stepObject) => setCurrentStep(stepObject);

  // This method is used by the player and it does not have all steps
  const handleGoToNextStep = () => {
    const nextIndex = hasNextStep ? currentStepIndex + 1 : course?.steps?.length - 1;
    setCurrentStep(course?.steps?.at(nextIndex));
  };

  // This method is used by the player and it does not have all steps
  const handleGoToPreviousStep = () => {
    const nextIndex = hasPreviousStep ? currentStepIndex - 1 : 0;
    setCurrentStep(course?.steps?.at(nextIndex));
  };

  const markStepAsCompleted = () =>
    setCourse((prev) => ({
      ...prev,
      accountCourse: {
        ...prev?.accountCourse,
        completedLessonIds: uniq([...(prev?.accountCourse?.completedLessonIds ?? []), currentStep?.id]),
      },
    }));

  const goToQuiz = () =>
    setCurrentStep(course?.steps?.find((el) => el?.type === COURSE_STEP_TYPE.QUIZ) ?? course?.steps?.at(0));

  return {
    currentStep,
    course,
    user,
    hasPreviousStep,
    hasNextStep,
    quizResultsPercentage,
    handleStepChange,
    handleGoToNextStep,
    handleGoToPreviousStep,
    markStepAsCompleted,
    goToQuiz,
    refreshQuizResults: fetchQuizResults,
  };
};
