import { StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';

Font.register({
  family: 'Roboto-Regular',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf',
  fontStyle: 'normal',
  fontWeight: 400,
});

Font.register({
  family: 'Roboto-Bold',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc9.ttf',
  fontStyle: 'normal',
  fontWeight: 700,
});

Font.register({
  family: 'Roboto-Light',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fBBc9.ttf',
  fontStyle: 'normal',
  fontWeight: 300,
});

Font.register({
  family: 'Poppins-Regular',
  src: 'https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrJJfedw.ttf',
  fontStyle: 'normal',
  fontWeight: 400,
});

Font.register({
  family: 'Poppins-Medium',
  src: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf',
  fontStyle: 'normal',
  fontWeight: 500,
});

Font.register({
  family: 'Poppins-Bold',
  src: 'https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7Z1xlEA.ttf',
  fontStyle: 'normal',
  fontWeight: 600,
});

export default StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  wrap: {
    width: '100%',
    height: '100%',
  },
  bgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: 600,
    zIndex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    zIndex: 2,
  },
  logo: {
    width: 70,
    height: 70,
    alignSelf: 'center',
    marginBottom: 24,
    marginTop: 130,
  },
  name: {
    fontFamily: 'Poppins-Bold',
    fontSize: 50,
    color: '#000C3F',
    marginBottom: 12,
    alignSelf: 'center',
  },
  successText: {
    fontFamily: 'Roboto-Regular',
    fontWeight: 400,
    fontSize: 18,
    marginBottom: 18,
    alignSelf: 'center',
  },
  courseName: {
    fontFamily: 'Poppins-Bold',
    fontWeight: 600,
    color: '#000C3F',
    fontSize: 32,
    alignSelf: 'center',
    marginBottom: 75,
  },
  certificateTextWrap: {
    textAlign: 'center',
  },
  certificateText: {
    fontFamily: 'Roboto-Bold',
    color: '#ffffff',
    fontSize: 45,
    lineHeight: 1.1,
    marginBottom: 12,
  },
  completionText: {
    fontFamily: 'Roboto-Regular',
    color: '#ffffff',
    fontSize: 24,
    lineHeight: 1.1,
    marginBottom: 24,
  },
  dateWrap: {
    textAlign: 'center',
  },
  dateWrapper: {
    position: 'absolute',
    bottom: -10,
    left: -50,
  },
  date: {
    fontSize: 16,
    color: '#222225',
    borderBottom: '1px solid #000C3F',
    paddingBottom: 4,
  },
  dateOfIssue: {
    color: '#56585E',
    fontSize: 16,
    marginTop: 4,
  },
  footerLogo: {
    width: 180,
    height: 40,
  },
});
