export const icon = [
  {
    icon: 'fab fa-facebook-f',
    path: 'https://www.facebook.com/profile.php?id=61573212916959',
  },
  {
    icon: 'fab fa-instagram',
    path: 'https://www.instagram.com/oneacademyofficial/',
  },
];

export const productElements = (getText) => [
  {
    title: getText('allCourses'),
    path: '/courses',
  },
  {
    title: getText('myCourses'),
    path: '/my-courses',
  },
];
