import { Row, Col, RecommendedCourses, RecommendedCategories, DashboardHeader, ContinueLearning } from 'components';
import { wrapper, dashboardRowStyle, dashboardHeaderStyles } from './styles';

const Dashboard = () => {
  return (
    <>
      <section className="header" css={dashboardHeaderStyles}>
        <Row container css={dashboardRowStyle}>
          <Col>
            <DashboardHeader />
          </Col>
        </Row>
      </section>
      <section css={wrapper}>
        <Row container>
          <Col>
            <ContinueLearning />
          </Col>
        </Row>
        <Row container>
          <Col>
            <RecommendedCourses />
          </Col>
        </Row>
        <RecommendedCategories />
      </section>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
