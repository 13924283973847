import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Icon } from 'components';
import { COURSE_STEP_TYPE, COURSE_STEP_TYPE_ICON } from 'enum';
import { durationInMinutes } from 'utils';
import { asideStyle, videoItemWrap, percentWrap, percentLabel } from './styles';

const CourseCurriculum = ({ course, onChange, currentStep }) => {
  const { getText, language } = useTranslations();

  const completedLessonsCount = course?.accountCourse?.completedLessonIds?.length ?? 0;
  const completedLessonProgress = Math.floor((completedLessonsCount / course?.courseLessonsCount) * 100);

  const matchTranslation = (step) => step?.translations?.find((el) => el.languageCode === language?.code) ?? step;

  return (
    <aside css={asideStyle}>
      <h5 className="course-title">{getText(course?.name)}</h5>
      <ul className="course-curriculum-list">
        {course?.steps.map((el, index) => {
          const step = matchTranslation(el);

          return (
            <li key={el.id} css={videoItemWrap(el?.id === currentStep?.id)}>
              <Button outline onClick={() => onChange(el)} className="course-curriculum-item">
                <div className="item-icon-and-text">
                  <Icon iconName={COURSE_STEP_TYPE_ICON[el.type]} />
                  <p>
                    {index + 1}. {getText(el?.title)}
                  </p>
                </div>
                {el.type === COURSE_STEP_TYPE.VIDEO && (
                  <span className="item-duration">{durationInMinutes(step.duration)}</span>
                )}
              </Button>
            </li>
          );
        })}
      </ul>

      <div css={percentWrap}>
        <div className="progress">
          <div
            className="progress-bar"
            style={{ width: `${completedLessonProgress}%` }}
            role="progressbar"
            aria-valuenow={completedLessonProgress}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <span css={percentLabel}>{`${completedLessonProgress}% ${getText('completed')}`}</span>
      </div>
    </aside>
  );
};

CourseCurriculum.propTypes = {
  course: PropTypes.object,
  onChange: PropTypes.func,
  currentStep: PropTypes.object,
};

export default CourseCurriculum;
