import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, showApiError } from 'components';
import { useDeepEffect } from 'hooks';
import { COURSE_STEP_TYPE } from 'enum';
import { getQuizByStepId, getLessonByStepId } from 'services';
import { VideoLoader, QuizResults } from './AdditionalElements';
import { VideoViewer } from './VideoViewer';
import { PdfViewer } from './PdfViewer';
import { QuizViewer } from './QuizViewer';
import { coursePlayerContainer, playerArrowControls } from './styles';

export const CoursePlayer = (props) => {
  const {
    user,
    course,
    currentStep,
    quizResultsPercentage,
    onNextStep,
    onPreviousStep,
    hasPreviousStep,
    hasNextStep,
    markStepAsCompleted,
    goToQuiz,
    refreshQuizResults,
  } = props;
  const [stepData, setStepData] = useState(currentStep);
  const [showLoading, setShowLoading] = useState(true);
  const [quizResults, setQuizResults] = useState(null);

  const isQuiz = stepData?.type === COURSE_STEP_TYPE.QUIZ;
  const isVideo = stepData?.type === COURSE_STEP_TYPE.VIDEO;
  const isPdf = stepData?.type === COURSE_STEP_TYPE.TEXT;
  const accountId = user?.academyAccount?.id;

  useDeepEffect(() => {
    fetchLesson();
  }, [currentStep]);

  const fetchLesson = async () => {
    quizResults && setQuizResults(null);
    handleShowLoading();
    // Always delete the old step data because we need appropriate switch between Video, PDF and Quiz
    // If the old step is Video and the new step is Quiz it will render the Quiz without questions before the response
    setStepData(null);

    const [res, err] = await (currentStep?.type === COURSE_STEP_TYPE.QUIZ
      ? getQuizByStepId({ stepId: currentStep?.id, accountId })
      : getLessonByStepId(currentStep?.id, accountId));

    if (err) return showApiError(err);
    setStepData({ ...currentStep, ...res });
    res?.type !== COURSE_STEP_TYPE.VIDEO && handleHideLoading();
  };

  const handleNextVideoStep = () => {
    markStepAsCompleted();
    handleShowLoading();
    onNextStep();
  };

  const handleShowLoading = () => setShowLoading(true);

  const handleHideLoading = () => setShowLoading(false);

  const handleNextQuizStep = (scorePercentage) => setQuizResults(scorePercentage);

  const handleGoToQuiz = () => {
    setQuizResults(null);
    goToQuiz();
  };

  return (
    <div css={coursePlayerContainer}>
      {showLoading && <VideoLoader />}
      {isVideo && (
        <VideoViewer
          currentStep={stepData}
          course={course}
          onNextStep={handleNextVideoStep}
          onReady={handleHideLoading}
        />
      )}
      {isPdf && <PdfViewer currentStep={stepData} />}
      {isQuiz && (
        <QuizViewer
          key={quizResults}
          currentStep={stepData}
          course={course}
          user={user}
          onNextStep={handleNextQuizStep}
          quizResultsPercentage={quizResultsPercentage}
          refreshQuizResults={refreshQuizResults}
        />
      )}
      {quizResults && <QuizResults quizResultsPercentage={quizResults} goToQuiz={handleGoToQuiz} />}
      {hasPreviousStep && (
        <Icon iconName="las la-chevron-left" onClick={onPreviousStep} size={18} css={playerArrowControls} />
      )}
      {hasNextStep && <Icon iconName="las la-chevron-right" onClick={onNextStep} size={18} css={playerArrowControls} />}
    </div>
  );
};

CoursePlayer.propTypes = {
  user: PropTypes.object,
  course: PropTypes.object,
  currentStep: PropTypes.object,
  quizResultsPercentage: PropTypes.number,
  onNextStep: PropTypes.func,
  onPreviousStep: PropTypes.func,
  hasPreviousStep: PropTypes.bool,
  hasNextStep: PropTypes.bool,
  markStepAsCompleted: PropTypes.func,
  goToQuiz: PropTypes.func,
  refreshQuizResults: PropTypes.func,
};
