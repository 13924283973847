import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { groupBy } from 'lodash-es';
import { Row, Col, CourseCard } from 'components';
import { categoryTitle, myCoursesContainer, customCardStyle, groupedCoursesListStyles } from './styles';

export const GroupedCoursesList = ({ courses }) => {
  const { getText } = useTranslations();

  const groupedCourses = groupBy(courses, (course) => course?.category?.name);

  return Object.entries(groupedCourses).map(([categoryName, categoryCourses]) => (
    <div key={categoryName} css={groupedCoursesListStyles}>
      <Row container>
        <h3 css={categoryTitle}>{getText(categoryName)}</h3>
      </Row>
      <Row gap={12} noWrap container css={myCoursesContainer(false)}>
        {categoryCourses.map((course) => (
          <Col xs={12} sm={6} md={4} lg={2.4} key={course.id} css={customCardStyle} className="bottomBuffer">
            <CourseCard course={course} />
          </Col>
        ))}
      </Row>
    </div>
  ));
};

GroupedCoursesList.propTypes = {
  courses: PropTypes.array,
};
