import { svgLoader } from './styles';

export const VideoLoader = () => {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="none" data-spinner="true" css={svgLoader}>
      <circle className="loader-background" cx="24" cy="24" r="22" stroke="white" />
      <circle className="loader-circle" cx="24" cy="24" r="22" stroke="white" />
    </svg>
  );
};
