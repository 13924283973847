export const container =
  ({ withShadow }) =>
  (theme) =>
    [
      {
        width: '100%',
        backgroundColor: theme.primary,
      },
      withShadow && {
        boxShadow: '0 6px 8px 0 rgba(224, 225, 229, 0.58)',
      },
    ];

export const contentContainer = ({ noPadding }) => ({
  padding: noPadding ? 0 : '24px 32px',
  height: '100%',
});

export const headerContainer = {
  padding: '20px 32px',
};
