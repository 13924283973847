import PropTypes from 'prop-types';
import moment from 'moment';
import { Page, Text, Image, View, Document } from '@react-pdf/renderer';
import { useTranslations } from '@veraio/strank';
import styles from './styles';
import certificateTemplate from 'assets/certificates/certificateFrame.png';
import certificateLogo from 'assets/certificates/certificateLogo.png';
import certificateFooterLogo from 'assets/certificates/footerLogo.png';

const Certificate = ({ profile, course }) => {
  const { getText } = useTranslations();

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.wrap}>
          <Image src={certificateTemplate} allowDangerousPaths style={styles.bgImage} />
          <View style={styles.row}>
            <View style={styles.content}>
              <Image src={certificateLogo} allowDangerousPaths style={styles.logo} />
              <View>
                <Text style={styles.name}>{`${profile?.firstName} ${profile.lastName}`}</Text>
                <View style={styles.leftColSeparator} />
                <Text style={styles.successText}>
                  {"Has successfully completed with distinction One Academy's Course"}
                </Text>
                <Text style={styles.courseName}>{getText(course?.name)}</Text>
              </View>
              <View style={styles.dateWrapper}>
                <Text style={styles.date}>{moment().format('DD/MM/YYYY')}</Text>
                <Text style={styles.dateOfIssue}>Date of issue</Text>
              </View>
              <View>
                <Image src={certificateFooterLogo} allowDangerousPaths style={styles.footerLogo} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

Certificate.propTypes = {
  profile: PropTypes.object,
  course: PropTypes.object,
};

export default Certificate;
