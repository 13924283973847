import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isNil, isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Skeleton, showApiError, useAuth } from 'components';
import { getAllFriends } from 'services';
import userImg from 'assets/images/user-img.png';
import noFriends from 'assets/images/noFriendsRankList.svg';
import { wrapper, header, body, listItemStyle, footer, noData, seeAllStyles } from './styles';

export const FriendsRankList = () => {
  const { getText } = useTranslations();
  const {
    user: {
      academyAccount: { id: accountId },
      currentAccount,
    },
  } = useAuth();

  const [friends, setFriends] = useState(null);

  useEffect(() => {
    fetchMyFriends();
  }, [currentAccount?.id]);

  const fetchMyFriends = async () => {
    const [res, err] = await getAllFriends(accountId, {
      includeDetails: true,
      pageIndex: 0,
      pageSize: 5,
      sort: {
        sortField: 'id',
        sortOrder: 'desc',
      },
    });
    if (err) return showApiError(err);

    setFriends(res?.data ?? []);
  };

  return (
    <div css={wrapper}>
      <div css={header}>
        <h6 className="title">{getText('rankingList')}</h6>
      </div>
      <div css={body}>
        {!isNil(friends) ? (
          !isEmpty(friends) ? (
            friends.map((el) => (
              <div css={listItemStyle} key={el.externalAccountId}>
                <div className="item-left">
                  <img src={el?.pictureUrl ?? userImg} className="avatar" alt="course-card-logo" />
                  <div>
                    <p className="name">
                      {el?.firstName} {el?.lastName}
                    </p>
                    <div className="points">
                      {`${el?.accomplishment?.points} ${getText('points')}`}{' '}
                      {!isEmpty(el?.accomplishment?.badges) && (
                        <span className="rank">
                          {getText(el?.accomplishment?.badges?.[el?.accomplishment?.badges.length - 1]?.name)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="item-right">
                  {!isEmpty(el?.accomplishment?.badges) &&
                    el?.accomplishment?.badges?.[el?.accomplishment?.badges.length - 1]?.pictureUrl && (
                      <img src={el.accomplishment.badges[el.accomplishment.badges.length - 1].pictureUrl} alt="" />
                    )}
                </div>
              </div>
            ))
          ) : (
            <div css={noData}>
              <img src={noFriends} alt="" />
              <p>{getText('noPeopleInNetwork')}</p>
            </div>
          )
        ) : (
          Array(5)
            .fill()
            .map((el, i) => (
              <div css={listItemStyle} key={i}>
                <div className="item-skeleton">
                  <span>{i + 1}</span>
                  <div className="skeleton-avatar">
                    <Skeleton circle height="32px" width="32px" />
                  </div>
                  <Skeleton height="14px" width="100%" />
                </div>
              </div>
            ))
        )}
      </div>
      <div css={footer}>
        {!isEmpty(friends) && (
          <Link to="/friends-rank-list" css={seeAllStyles}>
            {getText('seeAll')}
          </Link>
        )}
      </div>
    </div>
  );
};
