export const wrapper = (theme) => ({
  backgroundColor: theme.white,
  margin: 0,

  '& .bottomBuffer': {
    padding: 0,
  },
});

export const tabContainer = (theme) => ({
  padding: '2em 3em',
  backgroundColor: theme.backgroundLight,
});

export const aboutTab = (theme) => ({
  '& h6': {
    marginBottom: '1em',
  },
  '& p': {
    color: theme.gray,
    fontSize: '1rem',
    lineHeight: '1.5',
    marginBottom: '1.25em',
  },
});
