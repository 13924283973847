import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import { useTranslations } from '@veraio/strank';
import { useAuth } from 'components';
import { useAchievements, useCourses, getRandomCourses } from 'stores';
import defaultBadgeImage from 'assets/images/default-badge.svg';
import CarouselCard from './CarouselCard/CarouselCard';
import {
  avatarWrap,
  dashboardHeaderText,
  dashboardHeaderTextAndBtnWrapper,
  dashboardBtn,
  customArrowStyle,
  customDotStyle,
  wrapper,
} from './styles';

const responsive = {
  mobile: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
  },
};

export const DashboardHeader = () => {
  const { user } = useAuth();
  const { getText } = useTranslations();
  const achievements = useAchievements();
  useCourses();

  const { profile } = user;
  const courses = getRandomCourses();
  const { currentBadge, points } = achievements;

  const CustomArrow = ({ onClick, direction }) => (
    <div css={customArrowStyle(direction)}>
      <button onClick={onClick} className="carousel-arrow">
        {direction === 'left' ? <i className="fas fa-chevron-left" /> : <i className="fas fa-chevron-right" />}
      </button>
    </div>
  );

  const CustomDot = ({ onClick, active }) => {
    return <div role="button" tabIndex={0} onClick={onClick} css={(theme) => customDotStyle(theme, active)} />;
  };

  return (
    <>
      <div css={avatarWrap}>
        <img src={currentBadge?.pictureUrl ?? defaultBadgeImage} alt="dashboard-logo" />
        <div>
          <h2 className="userName">{`${getText('welcomeBack')}, ${profile?.firstName}`}</h2>
          <div css={dashboardHeaderTextAndBtnWrapper}>
            <p css={dashboardHeaderText}>{`${getText('youHaveEarned')} ${points} ${getText('points')}`}</p>
            {!!currentBadge && (
              <p css={dashboardHeaderText}>{`${getText('yourLevelIs')} ${getText(currentBadge?.name)}`}</p>
            )}
            <Link to="/achievements" css={dashboardBtn}>
              {getText('viewDetails')}
            </Link>
          </div>
        </div>
      </div>
      {!!courses?.length && (
        <Carousel
          showDots
          infinite
          autoPlay
          customDot={<CustomDot />}
          customLeftArrow={<CustomArrow direction="left" />}
          customRightArrow={<CustomArrow direction="right" />}
          responsive={responsive}
          autoPlaySpeed={3000}
          css={wrapper}>
          {courses?.map((course) => (
            <CarouselCard
              key={course.id}
              title={course.name}
              category={course?.category?.name}
              intro={course?.introText}
              lessons={course?.courseLessonsCount}
              totalVideoDurationInSeconds={course?.totalVideoDurationInSeconds}
              image={course?.picThumbUrl}
              isFeatured={course?.isFeatured}
              id={course?.id}
            />
          ))}
        </Carousel>
      )}
    </>
  );
};
