import { useTranslations } from '@veraio/strank';
import { Link } from 'react-router-dom';
import { useAchievements } from 'stores';
import defaultBadgeImage from 'assets/images/default-badge.svg';
import { footerCardStyles, logoAndTextWrapper, footerCardTitleAndDescription, dashboardBtn } from './styles';

const FooterCard = () => {
  const achievements = useAchievements();
  const { getText } = useTranslations();
  const { currentBadge, points } = achievements;

  return (
    <div css={footerCardStyles}>
      <div css={logoAndTextWrapper}>
        <img src={currentBadge?.pictureUrl ?? defaultBadgeImage} alt="footer-logo" />
        <div>
          {!!currentBadge && (
            <p css={footerCardTitleAndDescription}>{`${getText('yourLevelIs')} ${getText(currentBadge?.name)}`}</p>
          )}
          <p css={footerCardTitleAndDescription}>{`${getText('youHaveEarned')} ${points} ${getText('points')}`}</p>
        </div>
      </div>
      <Link to="/achievements" css={dashboardBtn}>
        {getText('viewDetails')}&nbsp; <i className="fas fa-chevron-right" />
      </Link>
    </div>
  );
};

export default FooterCard;
