export const wrapper = (theme) => ({
  position: 'relative',
  '& .search-input': {
    borderRadius: 36,
    border: `solid 1px ${theme.gray1}`,
    opacity: 0.7,
    fontSize: 12,
    color: theme.gray4,
    padding: '0.25em 0.25em 0.25em 0.75em',
    lineHeight: '1',
    transition: 'all 0.3s ease',
    outline: 0,
    width: 250,
    height: 38,
    '&:focus': {
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: theme.gray4,
    },
    '&::-moz-placeholder': {
      color: theme.gray4,
    },
    '&:-ms-input-placeholder': {
      color: theme.gray4,
    },
    '&:-moz-placeholder': {
      color: theme.gray4,
    },
  },
  '& .search-input-icon': {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 4,
    top: 4,
    bottom: 4,
    backgroundColor: theme.purpleDark,
    borderRadius: '50%',
    width: 30,
    height: 30,
    color: theme.white,
  },
});
