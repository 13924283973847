import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button, showApiError, Skeleton, useAuth } from 'components';
import { getCourseMaterial } from 'services';
import pdfIcon from 'assets/images/file-icon-pdf.png';
import docIcon from 'assets/images/file-icon-doc.png';
import imgIcon from 'assets/images/file-icon-img.png';
import { resourceList } from './styles';

const isPdf = (file) => file?.mimeType === 'application/pdf';
const isImage = (file) => file?.mimeType.startsWith('image');

const fileIcon = (file) => (isPdf(file) && pdfIcon) || (isImage(file) && imgIcon) || docIcon;

const MaterialsList = (props) => {
  const { course } = props;
  const { getText, language } = useTranslations();
  const [materials, setMaterials] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    fetchMaterials();
  }, []);

  const fetchMaterials = async () => {
    const [res, err] = await getCourseMaterial(course?.id, { accountId: user?.academyAccount?.id });
    err ? showApiError(err) : setMaterials(res?.data ?? []);
  };

  const getMaterialUrl = (material) => {
    const matchTranslation = material?.translations?.find((el) => el.languageCode === language?.code);
    return matchTranslation?.sourceUrl ?? material?.sourceUrl;
  };

  const handleDownloadFile = (url, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return !isNull(materials) ? (
    materials.length ? (
      <div css={resourceList}>
        {materials?.map((el, index) => (
          <div key={index} className="course-material-container">
            <div>
              <img src={fileIcon(el)} alt="course-file" className="course-material-icon" />
              <span>{getText(el?.name)}</span>
            </div>
            <Button outline onClick={() => handleDownloadFile(getMaterialUrl(el), el?.name)}>
              {getText('download')}
            </Button>
          </div>
        ))}
      </div>
    ) : (
      <div css={{ textAlign: 'center', padding: '3em' }}>
        <h3>{getText('noMaterials')}</h3>
      </div>
    )
  ) : (
    <Skeleton count={5} height="24px" width="100%" />
  );
};
MaterialsList.propTypes = {
  course: PropTypes.object,
};
export default MaterialsList;
