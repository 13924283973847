export const durationInHours = (duration) => {
  if (!duration) return '0h 0m';

  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  return `${hours}h ${minutes}m`;
};

export const durationInMinutes = (duration) => {
  if (!duration) return '0m';

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes}m${seconds > 0 ? ` ${seconds}s` : ''}`;
};
