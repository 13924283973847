export const COURSE_STEP_TYPE = {
  TEXT: 'text',
  VIDEO: 'video',
  QUIZ: 'quiz',
};

export const COURSE_STEP_TYPE_ICON = {
  [COURSE_STEP_TYPE.TEXT]: 'las la-file-pdf',
  [COURSE_STEP_TYPE.VIDEO]: 'las la-play-circle',
  [COURSE_STEP_TYPE.QUIZ]: 'las la-question-circle',
};
