export const applicationContainer = {
  width: '100vw',
  minHeight: '100vh',
  paddingTop: 64,
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const contentContainer = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',
  flex: '1 0 0%',
  height: '100%',
};
