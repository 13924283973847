import { Route } from 'react-router-dom';
import AllCourses from './AllCourses';
import FriendsRankList from './FriendsRankList';
import Course from './Course';
import MyCourses from './MyCourses';
import Dashboard from './Dashboard';
import Achievements from './Achievements';
import CourseDetails from './CourseDetails';
import HowDoesItWork from './HowDoesItWork';
import TermsAndConditions from '../Public/TermsAndConditions';
import PrivacyPolicy from '../Public/PrivacyPolicy';

export default (isActive) =>
  [
    {
      exact: true,
      path: '/dashboard',
      component: Dashboard,
    },
    {
      exact: true,
      path: '/my-courses',
      component: MyCourses,
    },
    isActive && {
      path: '/course/:id',
      component: Course,
    },
    {
      exact: true,
      path: '/courses',
      component: AllCourses,
    },
    {
      path: '/friends-rank-list',
      component: FriendsRankList,
    },
    {
      path: '/achievements',
      component: Achievements,
    },
    {
      path: '/how-does-it-work',
      component: HowDoesItWork,
    },
    {
      path: '/terms-and-conditions',
      component: TermsAndConditions,
    },
    {
      path: '/privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path: '/course-details/:id',
      component: CourseDetails,
    },
  ]
    .filter(Boolean)
    .map((route) => <Route key={route.path} {...route} />);
