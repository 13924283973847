export const categoryTitle = {
  fontSize: '1.25rem',
  paddingBottom: '0.25em',
  marginBottom: '0.5em',
};

export const myCoursesContainer = (noCourses) => ({
  justifyContent: noCourses ? 'center' : 'flex-start',
  margin: noCourses ? '3em 0' : '0 -0.5em',
  marginBottom: '2em',
});

export const customCardStyle = {
  flexBasis: '20%',
  maxWidth: '20%',
  padding: '0.5em',
};

export const groupedCoursesListStyles = {
  width: '100%',
  '@media (max-width: 576px)': {
    padding: '0 1.5em',
  },
};
