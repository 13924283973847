import achievementsIcon from 'assets/images/achievements-medal.svg';

export const publicLinks = (getText) => [
  {
    path: '/home',
    label: getText('dashboard'),
  },
  {
    path: '/courses',
    label: getText('courses'),
  },
  {
    path: '/products',
    label: getText('products'),
  },
  {
    path: '/get-started',
    label: getText('getStarted'),
  },
];

export const privateLinks = (getText) => ({
  left: [
    {
      path: '/courses',
      label: getText('allCourses'),
    },
  ],
  right: [
    {
      path: '/achievements',
      label: getText('achievements'),
      icon: achievementsIcon,
    },
    {
      path: '/my-courses',
      label: getText('myCourses'),
    },
  ],
});
