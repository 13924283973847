import { createStore, useStore, isNumber, isNil } from '@veraio/core';
import { shuffle } from 'lodash-es';
import { showApiError } from 'components';
import { getAllCourses } from 'services';

const initState = null;

const coursesStore = createStore(initState);

export const initCoursesStore = async () => {
  const [res, err] = await getAllCourses({ includeDetails: true });
  if (err) return showApiError(err);

  coursesStore.setState(res?.data);
};

export const getRandomCourses = (length) => {
  const courses = coursesStore?.getState();

  if (isNil(courses)) return null;

  return courses?.length ? shuffle(courses).slice(0, isNumber(length) ? length : 4) : [];
};

export const useCourses = (callback) => useStore(coursesStore, callback);
