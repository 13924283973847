import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Row, Icon, useAuth } from 'components';
import { durationInMinutes } from 'utils';
import { ReactComponent as PdfIcn } from 'assets/images/icon-pdf.svg';
import { courseDetails, startNowBtn, iconAndText, includesWrapper, heading, breadcrumbStyle } from './styles';

const CourseDetailsHeader = ({ course }) => {
  const { user } = useAuth();
  const { getText } = useTranslations();

  const { catalogueItems } = course ?? {};
  const { myPackages, profile, currentAccount } = user ?? {};
  const hasAccess = myPackages?.some((pac) => catalogueItems?.some((el) => el.catalogueItemId === pac.catalogueItemId));
  const isActive = profile?.status?.toLowerCase() === 'active' && currentAccount?.status?.toLowerCase() === 'active';

  return (
    <div className="header" css={courseDetails}>
      <Row container>
        <div className="details-wrapper">
          <div className="header-left">
            <div css={breadcrumbStyle} className="breadcrumb">
              <h5 css={heading}>{getText('courses')}</h5>
              <Icon iconName="la la-angle-right" />
              <span>{getText(course?.category?.name)}</span>
            </div>
            <h2 className="details-title">{getText(course?.name)}</h2>
            <p className="details-info">{getText(course?.introText)}</p>
            <div className="course-include" css={includesWrapper}>
              <h6>{getText('courseInclude')}</h6>
              {course?.courseFileResourcesCount > 0 && (
                <div css={iconAndText}>
                  <PdfIcn />
                  <p>e-book (PDF)</p>
                </div>
              )}
              <div css={iconAndText}>
                <Icon iconName="la la-file" />
                <p>{`${course?.steps.length} ${getText('lessons')}`}</p>
              </div>
              <div css={iconAndText}>
                <Icon iconName="la la-clock" />
                <p>{durationInMinutes(course?.totalVideoDurationInSeconds) ?? 'N/A'}</p>
              </div>
            </div>
          </div>
          <div className="header-right">
            <img className="responsive-img" src={course?.picThumbUrl} alt="" />
            <div className="info-and-button">
              {isActive ? (
                <p>{getText(hasAccess ? 'youHaveAlreadyPurchasedThisCourse' : 'courseDontHave')}</p>
              ) : (
                <h4>{getText('inactiveProfileAccount')}</h4>
              )}
              {isActive &&
                (hasAccess ? (
                  <Link to={`/course/${course?.id}`} css={startNowBtn}>
                    {getText('startNow')}
                  </Link>
                ) : (
                  <a
                    href={`${applicationUrls.oneLife}/shop/package-details/${course?.catalogueItems[0]?.catalogueItemId}`}
                    target="_blank"
                    rel="noreferrer"
                    css={startNowBtn}>
                    {getText('buyNow')}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default CourseDetailsHeader;
