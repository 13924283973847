import { useTranslations } from '@veraio/strank';
import Dropdown from '../Dropdown';
import { dropdownStyles, dropdownValueStyles } from './styles';

const LanguageDropdown = () => {
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();

  const switchLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
  };

  return (
    <Dropdown
      onTop
      options={allEnvLanguages ?? []}
      renderSelectedValue={(selected) => (
        <div css={dropdownValueStyles}>
          <h4>{getText(selected.name)}</h4>
        </div>
      )}
      value={language?.code}
      onChange={switchLanguage}
      noClear
      displayKey="name"
      uniqueKey="code"
      css={dropdownStyles}
      rightIcon={{ color: 'white' }}
    />
  );
};

export default LanguageDropdown;
