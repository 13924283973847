import { keyframes } from '@emotion/react';

export const baseButton =
  ({ disabled, color, backColor, isLoading }) =>
  (theme) =>
    [
      {
        position: 'relative',
        borderRadius: 4,
        cursor: disabled ? 'not-allowed' : 'pointer',
        padding: isLoading ? '10px 30px 10px 20px' : '10px 20px',
        color: theme[color] || theme.white,
        border: `1px solid ${theme.primaryLighter2}`,
        letterSpacing: 0.5,
        lineHeight: '1',
        textAlign: 'center',
        opacity: disabled ? 0.4 : 1,
        backgroundColor: theme[backColor] || theme.primaryLighter2,
        userSelect: 'none',
        outline: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        fontSize: 14,
        '&:hover': {
          backgroundColor: disabled ? theme.primaryLighter2 : theme.primary,
        },

        '&:focus, &:active': {
          backgroundColor: theme.primaryLighter2,
          color: disabled ? theme.white : theme.error,
        },

        '& > i': {
          marginRight: 6,
        },
      },
      disabled && {
        '& *': {
          pointerEvents: 'none',
        },
      },
    ];

export const successButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.white,
    border: `1px solid ${theme.successDark}`,
    backgroundColor: theme.successDark,

    '&:hover': {
      backgroundColor: disabled ? theme.successLight : theme.successDark,
    },

    '&:focus, &:active': {
      backgroundColor: disabled ? theme.successLight : theme.successDark,
      color: disabled ? theme.successLight : theme.gray3,
    },
  });

export const warningButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.white,
    border: `1px solid ${theme.warning}`,
    backgroundColor: theme.warning,

    '&:hover': {
      backgroundColor: disabled ? theme.warning : theme.error,
    },

    '&:focus, &:active': {
      backgroundColor: disabled ? theme.warning : theme.error,
      color: disabled ? theme.warning : theme.gray3,
    },
  });

export const primaryButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.white,
    borderRadius: 4,
    backgroundColor: theme.yellow,
    border: 'none',
    '&:hover': {
      backgroundColor: theme.warning,
    },

    '&:focus, &:active': {
      backgroundColor: disabled ? theme.secondaryLighter : theme.primaryLighter2,
      color: disabled ? theme.secondaryLighter : theme.gray3,
    },
  });

export const secondaryButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.info,
    border: `1px solid ${theme.secondaryLighter}`,
    backgroundColor: theme.secondaryLighter,

    '&:hover': {
      backgroundColor: disabled ? theme.secondaryLighter : theme.primaryLighter,
    },

    '&:focus, &:active': {
      backgroundColor: disabled ? theme.secondaryLighter : theme.primaryLighter2,
      color: disabled ? theme.secondaryLighter : theme.gray3,
    },
  });

export const outlineButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.gray1,
    border: `1px solid ${theme.gray1}`,
    backgroundColor: 'transparent',

    '&:hover': {
      border: `1px solid ${disabled ? theme.gray1 : theme.gray3}`,
      backgroundColor: 'transparent',
    },

    '&:focus, &:active': {
      color: disabled ? theme.gray1 : theme.gray3,
      border: `1px solid ${disabled ? theme.gray1 : theme.gray4}`,
      backgroundColor: 'transparent',
    },
  });

export const outlineInverseButton =
  ({ disabled }) =>
  (theme) => ({
    color: theme.gray3,
    border: `1px solid ${theme.gray}`,
    backgroundColor: 'transparent',

    '&:hover': {
      border: `1px solid ${disabled ? theme.gray : theme.gray3}`,
      backgroundColor: 'transparent',
    },

    '&:focus, &:active': {
      color: disabled ? theme.gray3 : theme.gray1,
      border: `1px solid ${disabled ? theme.gray : theme.gray1}`,
      backgroundColor: 'transparent',
    },
  });

export const clearButton = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textAlign: 'left',
};

export const smallButton = {
  fontSize: 12,
  padding: '6px 16px',
};

export const largeButton = {
  fontSize: 16,
  padding: '16px 32px',
};

export const checkButtonContainer = {
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 600,
};

export const iconContainer = {
  marginRight: 10,
};

export const buttonDropdownContainer = {
  position: 'relative',
  outline: 'none',
  fontSize: '16px',
};

const itemHeight = 51;

export const buttonDropdownOptionsContainer = (isOpened, maxNumber, numberOfItems, optionListClassName) => (theme) =>
  [
    {
      position: 'absolute',
      top: '100%',
      right: 0,
      zIndex: 150,
      width: 'auto',
      color: theme.disabled,
      height: 'auto',
      maxHeight: maxNumber * itemHeight - itemHeight / 2,
      overflowY: numberOfItems > maxNumber ? 'auto' : 'hidden',
      backgroundColor: 'white',
      transform: `scaleY(${isOpened ? 1 : 0})`,
      transformOrigin: 'top',
      transition: 'transform 400ms ease-in-out',
      boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
      borderTop: isOpened ? '1px solid #DDDDDD' : 'none',
      borderRadius: '0 0 4px 4px',
    },
    optionListClassName,
  ];

export const buttonDropdownOptionItem = (theme) => ({
  padding: '16px 32px 16px 16px',
  color: theme.disabled,
  backgroundColor: 'transparent',
  display: 'inline-flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  width: '100%',
  '&:focus': {
    outline: 'none',
  },
  '& i, & img': {
    marginRight: 10,
  },
});

export const switchButtonContainer = (width) => ({
  position: 'relative',
  width: width || 70,
  userSelect: 'none',
});

export const switchButtonInput = {
  display: 'none',
};

export const switchButtonLabel = {
  display: 'block',
  overflow: 'hidden',
  cursor: 'pointer',
  width: '100%',
  borderRadius: 20,
};

export const switchButtonInner =
  (
    isChecked,
    activeText,
    inactiveText,
    {
      backgroundColorBefore,
      activeTextColor,
      backgroundColorAfter,
      inactiveTextColor,
      fontSizeLabels,
      textSpacingLabels,
      sizingHeight,
    },
  ) =>
  (theme) => ({
    display: 'block',
    width: '200%',
    marginLeft: isChecked ? 0 : '-100%',
    transition: 'margin 0.3s ease-in 0s',

    '&:before, &:after': {
      display: 'inline-block',
      width: '50%',
      height: sizingHeight || 30,
      padding: 0,
      lineHeight: `${sizingHeight || 30}px`,
      fontSize: fontSizeLabels || 14,
      color: 'white',
      fontWeight: 'bold',
    },

    '&:before': {
      content: activeText ? `'${activeText}'` : "'ON'",
      paddingLeft: textSpacingLabels || 10,
      backgroundColor: backgroundColorBefore || theme.primary,
      color: activeTextColor || theme.white,
    },

    '&:after': {
      content: inactiveText ? `'${inactiveText}'` : "'OFF'",
      paddingRight: textSpacingLabels || 10,
      backgroundColor: backgroundColorAfter || theme.gray3,
      color: inactiveTextColor || theme.gray1,
      textAlign: 'right',
    },
  });

export const switchButtonCircle =
  (isChecked, width, { switchSize, switchBackgroundColor, switchBorderColor }) =>
  (theme) => ({
    display: 'block',
    width: switchSize || 20,
    height: switchSize || 20,
    background: switchBackgroundColor || theme.white,
    position: 'absolute',
    bottom: 5,
    right: isChecked ? 4 : (width || 70) - (switchSize || 24),
    border: `2px solid ${switchBorderColor || theme.grey} `,
    borderRadius: 20,
    transition: 'all 0.3s ease-in 0s',
  });

export const checkGroup = {
  '& > *': {
    marginRight: 8,
  },
};

const puff = [
  keyframes`
  0%  {transform: scale(0)}
  100% {transform: scale(1.0)}
`,
  keyframes`
  0%  {opacity: 1}
  100% {opacity: 0}
`,
];

export const loaderStyle = (theme) => ({
  position: 'absolute',
  right: 5,
  top: 'calc(50% - 10px)',
  height: 20,
  width: 20,
  border: `thick solid ${theme.white}`,
  borderRadius: '50%',
  opacity: '1',
  animationFillMode: 'both',
  animation: `${puff[0]}, ${puff[1]}`,
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1), cubic-bezier(0.3, 0.61, 0.355, 1)',
  animationDelay: '${i === 1 ? "-1s"',
});
