import { createStore, useStore } from '@veraio/core';
import { orderBy } from 'lodash-es';
import { showApiError } from 'components';
import { getAccountAchievements } from 'services';

const initState = {};

const achievementsStore = createStore(initState);

export const initAchievementsStore = async (id) => {
  const [res, err] = await getAccountAchievements(id);
  if (err) return showApiError(err);

  const levelBadges = res.badges.filter((el) => /level/gi.test(el.name));
  const newState = { ...res, currentBadge: orderBy(levelBadges, 'createdOn', 'desc').at(0) };

  achievementsStore.setState(newState);
};

export const useAchievements = (callback) => useStore(achievementsStore, callback);
