import { isNil, orderBy } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCategories } from 'stores';
import { Row, Col, Skeleton, Dropdown, CourseCategoryCard, SearchInput } from 'components';
import { AllCoursesHeader } from 'components/Pages/AllCourses';
import { useAllCoursesList } from './useAllCoursesList';
import {
  wrapper,
  skeletonStyle,
  categoryAndCardWrapper,
  sideBarStyle,
  contentStyle,
  pickCategory,
  customColCategoryCard,
  dropdownCategory,
  categoryIconStyle,
} from './styles';

const AllCourses = () => {
  const { getText } = useTranslations();
  const categories = useCategories();
  const { courses, activeCategory, searchText, handleCategorySelect, handleSearchChange } = useAllCoursesList(getText);

  const renderCategories = () => {
    if (isNil(categories)) return null;

    const allCategories = [
      {
        id: 0,
        name: getText('all'),
        iconUrl: 'https://oneecosystem.blob.core.windows.net/academy/icons/categories/category-all-icon.png',
      },
      ...orderBy(categories, 'name'),
    ];

    return (
      <div css={sideBarStyle}>
        {allCategories?.map((category) => (
          <div
            role="button"
            tabIndex={0}
            key={category.id}
            className="category-item"
            data-active={activeCategory === category.id}
            onClick={() => handleCategorySelect(category.id)}>
            <img css={categoryIconStyle} src={category?.iconUrl} alt="category-icon" />
            {getText(category?.name)}
          </div>
        ))}
      </div>
    );
  };

  const renderDropdown = () => {
    if (isNil(categories)) return null;

    const allCategories = [{ id: 0, name: getText('all') }, ...(categories ?? [])];
    const options = allCategories?.map((category) => ({
      label: getText(category?.name),
      value: category.id,
    }));

    return (
      <Dropdown
        options={options}
        autoWidth
        value={options.find((option) => option.value === activeCategory)}
        css={dropdownCategory}
        onChange={(option) => handleCategorySelect(option.value)}
      />
    );
  };

  const renderCards = () => {
    if (isNil(categories) || isNil(courses)) {
      return Array(5)
        .fill()
        .map((_, index) => (
          <div key={index} css={skeletonStyle}>
            <Skeleton height="280px" width="100%" />
          </div>
        ));
    }

    return courses?.map((course) => <CourseCategoryCard key={course.id} course={course} />);
  };

  return (
    <div css={wrapper}>
      <section className="header">
        <AllCoursesHeader />
      </section>
      <Row container css={pickCategory} className="section-title">
        <h5>{getText('pickCategory')}</h5>
        <SearchInput value={searchText} placeholder={getText('whatDoYouWantToLearn')} onChange={handleSearchChange} />
        {renderDropdown()}
      </Row>

      <Row container horizontalGap={12} noWrap css={categoryAndCardWrapper}>
        <Col className="categories-sidebar">
          {!isNil(categories) ? renderCategories() : <Skeleton height="300px" width="100%" />}
        </Col>

        <Col css={customColCategoryCard}>
          <div css={contentStyle}>{renderCards()}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AllCourses;
