import { isArray } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Row } from '../../../UI';
import { detailsDescription, whatYourWillLearn, learnTitle } from './styles';

const CourseDetailsDescription = ({ course }) => {
  const { getText } = useTranslations();

  return (
    <>
      <Row container>
        {course?.description && (
          <div css={detailsDescription}>
            <h2 className="section-title">{getText('description')}</h2>
            <p>{getText(course?.description)}</p>
          </div>
        )}
      </Row>
      <Row container>
        {isArray(course?.topics) && course?.topics.length > 0 && (
          <div css={whatYourWillLearn}>
            <h2 css={learnTitle} className="section-title">
              {getText('whatLearn')}
            </h2>
            <div className="items-wrapper">
              {course?.topics.map((el, index) => (
                <div className="check-and-text" key={index}>
                  <i className="fas fa-check-circle" />
                  <p>{getText(el)}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </Row>
    </>
  );
};

export default CourseDetailsDescription;
