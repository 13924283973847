export const footerContainer = (theme) => ({
  backgroundColor: theme.backgroundLight,
  color: theme.gray1,
  borderTop: `2px solid ${theme.gray3}`,
  fontWeight: 'bold',
  lineHeight: '1.7',
});

export const authenticatedFooter = {
  display: 'flex',
  margin: '0',
  padding: '2em 0',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  '@media (max-width: 991px)': {
    flexWrap: 'wrap',
    gap: '1.5em',
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 600px)': {
    padding: '2em',
  },
};

export const footerDescription = {
  maxWidth: '100%',
  padding: '0 15px',
  '@media (max-width: 768px)': {
    width: '100%',
  },
};

export const usernameBranchContainer = {
  display: 'flex',
};

export const appAvatarIcon = {
  height: 36,
};

export const descriptionStyles = (theme) => ({
  fontSize: '0.75rem',
  lineHeight: '1.5',
  color: theme.gray1,
  fontWeight: 400,
  paddingLeft: '8%',
  '@media (max-width: 450px)': {
    padding: '2em 0',
  },
});

export const iconsContainer = (theme) => ({
  display: 'flex',
  gap: '0.375em',
  '& .links': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: theme.white,
    color: theme.primary,
    transition: 'background-color 0.3s ease',
    textAlign: 'center',
  },
  '& .links:hover': {
    transform: 'scale(1.1)',
  },
  '& .links i': {
    fontSize: '0.875rem',
  },
});

export const footerColumn = {
  minWidth: '7rem',
  marginRight: '1em',
  '& > *': {
    display: 'block',
  },
};

export const columnTitle = (theme) => ({
  color: theme.gray,
  textTransform: 'uppercase',
  marginBottom: '1em',
  fontWeight: 400,
  '@media (max-width: 450px)': {
    fontSize: '0.85rem',
  },
});

export const footerLink = (isActive) => (theme) => ({
  opacity: isActive ? 1 : 0.6,
  color: theme.gray2,
  textTransform: 'none',
  marginBottom: '1em',
  fontSize: '1rem',
  fontWeight: 400,
  '&:last-of-type': {
    marginBottom: 0,
  },
  '@media (max-width: 450px)': {
    fontSize: '0.85rem',
  },
});

export const unAuthenticatedFooterStyles = (theme) => ({
  padding: '0.75rem 1rem',
  alignItems: 'center',
  backgroundColor: theme.primary,
});

export const unAuthenticatedFooterRowStyles = (theme) => ({
  display: 'flex',
  gap: '1em',
  justifyContent: 'space-between',
  lineHeight: '1.4',
  alignItems: 'center',
  backgroundColor: theme.primary,
});

export const footerLeft = (theme) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.75rem',
  alignItems: 'center',
  color: theme.white,
  fontSize: '0.6875rem',
  fontFamily: 'Roboto, sans-serif;',
  fontWeight: 300,
});

export const footerLinks = {
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
  },
};

export const footerRight = (theme) => ({
  color: theme.white,
  fontSize: '0.6875rem',
  display: 'flex',
  gap: '0.75rem',
  alignItems: 'center',
});

export const footerCardStyles = (theme) => ({
  backgroundColor: theme.primary,
  borderRadius: 8,
  padding: '0.75rem',
  color: theme.white,
  fontSize: '0.85rem',
  width: '16rem',
});

export const logoAndTextWrapper = {
  display: 'flex',
  gap: '1em',
  marginBottom: '1em',
  '& img': {
    width: 40,
    height: 40,
  },
};

export const footerCardBtn = (theme) => ({
  color: `${theme.white} !important`,
  padding: '0.75em 0.5em',
  marginTop: '1.5em',
  '@media (max-width: 450px)': {
    fontSize: '0.75rem',
  },
});

export const footerCardTitleAndDescription = {
  fontWeight: 400,
};

export const colEqual = {
  flex: '0 0 auto',
  width: '50%',
  maxWidth: '100%',
  padding: '0 15px',
};

export const colEqualDescription = {
  flex: '0 0 auto',
  width: '50%',
  maxWidth: '100%',
  padding: '0 15px',
  '@media (max-width: 768px)': {
    width: 'auto',
    marginTop: '1em',
  },
};

export const colAuto = {
  flex: '0 0 auto',
  width: 'auto%',
  maxWidth: '100%',
  padding: '0 15px',
};

export const rowWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -15px',
};

export const rowWrapperLeft = {
  display: 'flex',
  margin: '0 -15px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
};

export const rowWrapperNoWrap = {
  display: 'flex',
  margin: '0 -15px',
};

export const logoAndDescription = {
  display: 'flex',
  flexFlow: 'row nowrap',
  flex: 1,
  paddingRight: '12%',
  minWidth: '24rem',
  '@media (max-width: 450px)': {
    flexDirection: 'column',
    maxWidth: '100%',
    minWidth: 0,
  },
};

export const dashboardBtn = (theme) => ({
  borderRadius: 34,
  color: theme.white,
  backgroundColor: theme.primaryLighter2,
  padding: '0.5em 0.75em',
  fontWeight: 300,
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});
