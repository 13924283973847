import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { useHistory } from 'react-router-dom';
import { Button } from 'components';
import { formatShortText, durationInMinutes } from 'utils';
import testCourseImg from 'assets/images/test-course-image.jpg';
import { courseBox, buttonStyle, btnAndDurationCourseCard } from './styles';

export const MightLikeCard = ({ course }) => {
  const { getText } = useTranslations();
  const history = useHistory();

  const navigateToCourse = () => {
    history.push(`/course-details/${course?.id}`);
  };

  return (
    <div css={courseBox}>
      <div className="card-content">
        <div className="img-wrapper">
          <img src={course?.picThumbUrl ?? testCourseImg} alt="course-img" className="course-image" />
        </div>
        <div className="course-text-box">
          <h2 className="title">{getText(course?.name)}</h2>
          {course?.description && <p className="text">{formatShortText(getText(course?.introText))}</p>}
          <div css={btnAndDurationCourseCard}>
            <Button secondary onClick={navigateToCourse} css={buttonStyle}>
              {getText('view')}
            </Button>
            <p>
              <i className="far fa-clock" /> {durationInMinutes(course?.totalVideoDurationInSeconds)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

MightLikeCard.propTypes = {
  course: PropTypes.object.isRequired,
};
