import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { ThemeProvider } from '@emotion/react';
import { setDealshakerCoreConfig } from '@oneecosystem/dealshaker-core';
import { setAuthConfig, AUTH_EVENTS_TYPES } from '@oneecosystem/authenticate';
import { setStrankConfig } from '@veraio/strank';
import axios from 'axios';
import AuthProvider from './components/UI/Authenticate';
import ShopProvider from './components/UI/Shop';
import BaseApp from './screens/App/App';
import { ScrollToTop } from './components/UI';
import RedirectCallback from './screens/Public/RedirectCallback';
import LogoutPage from './screens/Public/Logout';
import { deleteReq, getReq, patchReq, postReq, putReq } from './services/axios/makeRequest';
import { trackGeoLocation } from './services/settings';
import initGlobalStyles from './styles/globalCSS';
import theme from './styles/theme.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

setAuthConfig({
  identity: { domain: identityProvider.host, clientId: identityProvider.clientId },
  oneLifeDomain: apiUrls.oneLife,
  requestAdapter: {
    get: getReq,
    post: postReq,
    put: putReq,
    patch: patchReq,
    delete: deleteReq,
  },
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    if (token?.access_token) axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: () => {
    trackGeoLocation();
    window.location = '/';
  },
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: trackGeoLocation,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = '/';
  },
});

setStrankConfig({ parseHtml: true, environment: strankEnvironment, storage: window.localStorage });

setDealshakerCoreConfig({
  dealshakerPortalApiDomain: apiUrls.dealshaker,
  oneLifeApiDomain: apiUrls.oneLife,
  storage: window.sessionStorage,
  requestInstance: {
    get: getReq,
    post: postReq,
    put: putReq,
    patch: patchReq,
    delete: deleteReq,
  },
});

const App = () => (
  <Router>
    <ScrollToTop>
      <ThemeProvider theme={theme}>
        <AuthProvider renderRedirect={RedirectCallback} renderLogout={LogoutPage}>
          <ShopProvider>
            {initGlobalStyles()}
            <BaseApp />
          </ShopProvider>
        </AuthProvider>
      </ThemeProvider>
    </ScrollToTop>
  </Router>
);

const container = document.getElementById('one-academy-root');
const root = createRoot(container);
root.render(<App />);
