import { useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import Icon from '../Icon';
import {
  baseButton,
  primaryButton,
  secondaryButton,
  successButton,
  warningButton,
  outlineButton,
  outlineInverseButton,
  clearButton,
  smallButton,
  largeButton,
  loaderStyle,
} from './styles';

const Button = (props) => {
  const {
    children,
    onClick,
    loading,
    disabled,
    leftIcon,
    primary,
    secondary,
    success,
    warning,
    outline,
    outlineInverse,
    clear,
    small,
    large,
    className,
    preventDefault,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const classes = [
    baseButton({ ...props, isLoading }),
    primary && primaryButton(props),
    (secondary || clear) && secondaryButton(props),
    success && successButton(props),
    warning && warningButton(props),
    outline && outlineButton(props),
    outlineInverse && outlineInverseButton(props),
    clear && clearButton,
    small && smallButton,
    large && largeButton,
  ];

  const handleClick = async (e) => {
    if (loading || isLoading || disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    setIsLoading(true);
    isFunction(onClick) && (await onClick(e));
    setIsLoading(false);
  };

  return (
    <div
      {...(isFunction(onClick) ? { tabIndex: '0', role: 'button', onClick: handleClick } : {})}
      {...(isString(className) && { className })}
      css={classes}>
      {leftIcon && <Icon {...leftIcon} />}
      {children}
      {(loading || isLoading) && <div css={loaderStyle} />}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  outline: PropTypes.bool,
  outlineInverse: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  color: PropTypes.string,
  backColor: PropTypes.string,
  clear: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  leftIcon: PropTypes.object,
};

export default Button;
