import { createStore, useStore } from '@veraio/core';
import { showApiError } from 'components';
import { getAllCategories } from 'services';

const categoriesStore = createStore(null);

export const initCategoriesStore = async (id) => {
  const [res, err] = await getAllCategories(id);
  err ? showApiError(err) : categoriesStore.setState(res?.data);
};

export const useCategories = (callback) => useStore(categoriesStore, callback);
