import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash-es';
import { COLUMN_DISPLAY_NAME } from './Col';
import { rowComp, containerComp } from './styles';

export const ROW_DISPLAY_NAME = 'GridRow';

const Row = (props) => {
  const { toArray } = Children;
  const { container, children: initChilds, xs, sm, md, lg, xl } = props;
  const { gap, verticalGap, horizontalGap, className } = props;
  const children = toArray(initChilds).filter(Boolean);

  const columnChildren = children.map((el, i) =>
    el?.type?.displayName === COLUMN_DISPLAY_NAME
      ? cloneElement(el, {
          key: `Column${i}`,
          gap,
          verticalGap,
          horizontalGap,
          xs: el.props.xs || xs,
          sm: el.props.sm || sm,
          md: el.props.md || md,
          lg: el.props.lg || lg,
          xl: el.props.xl || xl,
        })
      : el,
  );

  return container ? (
    <div css={containerComp(props)}>
      <div css={rowComp(props)} {...(isString(className) && { className })}>
        {columnChildren}
      </div>
    </div>
  ) : (
    <div css={rowComp(props)} {...(isString(className) && { className })}>
      {columnChildren}
    </div>
  );
};

Row.propTypes = {
  container: PropTypes.bool,
  children: PropTypes.any,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  gap: PropTypes.number,
  verticalGap: PropTypes.number,
  horizontalGap: PropTypes.number,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Row.displayName = ROW_DISPLAY_NAME;

export default Row;
