import queryString from 'query-string';
import { pick } from 'lodash-es';

export const getAllCoursesParams = (options) => {
  const defaultSort = {
    sortBy: 'courseOrder',
    sortDirection: 'asc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  const filterFields = [];
  const filterValues = [];

  if (options.categoryId) {
    filterFields.push('categoryId');
    filterValues.push(options.categoryId);
  }

  if (options.notAccountId) {
    filterFields.push('notAccountId');
    filterValues.push(options.notAccountId);
  }

  return queryString.stringify(
    {
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
      ...(options.includeDetails && { includeDetails: options.includeDetails }),
      ...(options.randomize && { randomize: options.randomize }),
      ...(filterFields.length && filterValues.length && { filterFields, filterValues }),
    },
    { arrayFormat: 'index' },
  );
};

export const getMyCoursesParams = (options) => {
  const defaultSort = { sortBy: 'id', sortDirection: 'asc' };

  const mapSort = (sort) => ({ sortBy: sort.sortField, sortDirection: sort.sortOrder });

  return queryString.stringify(
    {
      pageIndex: 0,
      pageSize: 100,
      ...defaultSort,
      includeDetails: true,
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort && mapSort(options.sort)),
      ...(options.accountId && { accountId: options.accountId }),
      ...(options.includeDetails && { includeDetails: options.includeDetails }),
    },
    { arrayFormat: 'index' },
  );
};

export const getAllCourseMaterialParams = (options) =>
  queryString.stringify(
    {
      ...(options.accountId && { accountId: options.accountId }),
      ...pick(options, ['pageIndex', 'pageSize']),
    },
    { arrayFormat: 'index' },
  );
