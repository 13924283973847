import { isNil, isEmpty } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { useMyCourses } from 'stores';
import { Row, Col, Skeleton, GroupedCoursesList } from 'components';
import { noData, continueLearningStyles, heading } from './styles';

export const ContinueLearning = () => {
  const { getText } = useTranslations();
  const myCourses = useMyCourses();

  if (!isNil(myCourses) && isEmpty(myCourses)) return null;

  return (
    <Row horizontalGap={12} noWrap container css={continueLearningStyles}>
      <div css={heading}>
        <h2 className="section-title">{getText('continueLearning')}</h2>
      </div>
      {!isNil(myCourses) ? (
        !isEmpty(myCourses) ? (
          <GroupedCoursesList courses={myCourses} />
        ) : (
          <div css={noData} />
        )
      ) : (
        <Row horizontalGap={12} noWrap container>
          {Array(5)
            .fill()
            .map((_, index) => (
              <Col xs={12} sm={6} md={4} lg={2.4} key={index} className="bottomBuffer">
                <Skeleton height="340px" width="100%" />
              </Col>
            ))}
        </Row>
      )}
    </Row>
  );
};
