export const pdfViewerContainer = {
  width: '100%',
  height: '100%',
  overflow: 'auto',
  position: 'relative',

  '&[data-fullscreen="true"]': {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '2000',
    backgroundColor: '#000',
  },

  '& .pdf-document': {
    display: 'flex',
    gap: '1em',
    justifyContent: 'center',

    '& .pdf-page': {
      backgroundColor: 'transparent !important',
    },
  },
};

export const pdfViewerControls = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  padding: '0.5em',

  '& .controls-container': {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    padding: '0.5em',
    borderRadius: 8,
  },
};

export const navButtons = (theme) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.primary,
  padding: '0.5em 1em',
  '& i': {
    color: theme.white,
    marginRight: 0,
  },
});
