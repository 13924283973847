import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { durationInMinutes } from 'utils';
import { ReactComponent as CourseCardIcn } from 'assets/images/icn-course-card.svg';
import {
  wrapper,
  header,
  courseThumb,
  body,
  percentWrap,
  percentLabel,
  courseCardHeading,
  cardSpansStyles,
} from './styles';

export const CourseCard = (props) => {
  const { getText } = useTranslations();
  const { course } = props;
  const { id, name, picThumbUrl, accountCourse, totalVideoDurationInSeconds } = course;

  const completedLessonsCount = accountCourse?.completedLessonIds?.length ?? 0;
  const completedLessonProgress = Math.floor((completedLessonsCount / course?.courseLessonsCount) * 100);

  return (
    <Link to={`/course-details/${id}`}>
      <div css={wrapper}>
        <div css={header}>
          {picThumbUrl ? <img src={picThumbUrl} css={courseThumb} alt={`course-card-${id}`} /> : <CourseCardIcn />}
        </div>
        <div css={body}>
          <h4 css={courseCardHeading}>{getText(name)}</h4>
          <div>
            <div css={percentWrap}>
              <div className="progress">
                <div
                  className="progress-bar"
                  style={{ width: `${completedLessonProgress}%` }}
                  role="progressbar"
                  aria-valuenow="0"
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </div>
            <div css={cardSpansStyles}>
              <span css={percentLabel}>{`${completedLessonProgress}% ${getText('completed')}`}</span>
              <i className="fas fa-circle" />
              <p>
                <i className="far fa-clock" /> {durationInMinutes(totalVideoDurationInSeconds)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

CourseCard.propTypes = {
  course: PropTypes.object,
};
