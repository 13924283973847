import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, showApiError, MightLikeCourses } from 'components';
import {
  CourseDetailsHeader,
  CourseDetailsDescription,
  CourseDetailsCurriculum,
} from '../../../components/Pages/CourseDetails';
import { getCourseById } from 'services';

const CourseDetails = () => {
  const { id: courseId } = useParams();
  const { user } = useAuth();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    getCourse();
  }, []);

  const getCourse = async () => {
    const [res, err] = await getCourseById(courseId, user?.academyAccount?.id);
    if (err) return showApiError(err);

    setCourse(res);
  };

  return (
    <>
      <CourseDetailsHeader course={course} />
      <CourseDetailsDescription course={course} />
      <CourseDetailsCurriculum course={course} />
      <MightLikeCourses />
    </>
  );
};

export default CourseDetails;
