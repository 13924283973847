export const noData = {
  display: 'none',
};

export const continueLearningStyles = {
  display: 'flex',
};

export const heading = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 576px)': {
    padding: '0 1.5em',
  },
};
