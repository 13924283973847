import PropTypes from 'prop-types';
import { Pagination } from '../../UI/Table';
import { Dropdown } from '../../UI';
import { footerBarContainer, footerDropdown } from './styles';

export const FooterBar = ({ tableOptions, getData }) => {
  return (
    <div css={footerBarContainer}>
      <Dropdown
        options={[5, 10, 15, 20]}
        value={tableOptions.current.pageSize}
        onChange={(value) => getData({ pageSize: value })}
        noClear
        css={footerDropdown}
      />
      <Pagination tableOptions={tableOptions} getData={getData} />
    </div>
  );
};

FooterBar.propTypes = {
  tableOptions: PropTypes.object,
  getData: PropTypes.func,
};
