import { isNil } from '@veraio/core';
import { CourseCurriculum, InfoTabs, CoursePlayer } from 'components';
import { useCourse } from './useCourse';
import { loader, coursePreviewContainer } from './style';

const Course = () => {
  const {
    course,
    currentStep,
    user,
    hasPreviousStep,
    hasNextStep,
    quizResultsPercentage,
    handleStepChange,
    handleGoToNextStep,
    handleGoToPreviousStep,
    markStepAsCompleted,
    goToQuiz,
    refreshQuizResults,
  } = useCourse();

  return !isNil(course) ? (
    <section css={coursePreviewContainer}>
      <div className="course-preview-container">
        <section className="course-player-container">
          <CoursePlayer
            user={user}
            course={course}
            currentStep={currentStep}
            hasPreviousStep={hasPreviousStep}
            hasNextStep={hasNextStep}
            quizResultsPercentage={quizResultsPercentage}
            onNextStep={handleGoToNextStep}
            onPreviousStep={handleGoToPreviousStep}
            markStepAsCompleted={markStepAsCompleted}
            goToQuiz={goToQuiz}
            refreshQuizResults={refreshQuizResults}
          />
        </section>
        <InfoTabs course={course} quizResultsPercentage={quizResultsPercentage} />
      </div>
      <CourseCurriculum course={course} onChange={handleStepChange} currentStep={currentStep} />
    </section>
  ) : (
    <div css={loader}>
      <svg className="spinner" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Course;
