import { shuffle } from 'lodash-es';
import { getReq, postReq } from './axios/makeRequest';

export const getQuizByStepId = async (params) => {
  const [res, err] = await getReq(`${apiUrls.oneAcademy}/quizzes`, { params });

  let response = res;

  if (projectEnvironment !== 'development')
    response = { ...res, questions: shuffle(res.questions?.map((el) => ({ ...el, answers: shuffle(el.answers) }))) };

  return [response, err];
};

export const postNewQuizHistory = (data) => postReq(`${apiUrls.oneAcademy}/quiz-history/new`, data);

export const quizResults = ({ accountId, courseId, pageIndex = 0, pageSize = 5 }) =>
  getReq(`${apiUrls.oneAcademy}/quiz-history/quiz-takes/list`, {
    params: { accountId, courseId, pageIndex, pageSize },
  });
