import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isArray } from '@veraio/core';
import { showApiError } from 'components';
import { initAchievementsStore } from 'stores';
import { postNewQuizHistory } from 'services';
import { courseQuizContainer } from './styles';

export const QuizViewer = (props) => {
  const { course, currentStep, user, onNextStep, quizResultsPercentage, refreshQuizResults } = props;
  const { getText } = useTranslations();
  const [currentQuestion, setCurrentQuestion] = useState(currentStep?.questions?.at(0));
  const [markedAnswer, setMarkedAnswer] = useState();
  const quizResultsRef = useRef({});

  const questionIndex = currentStep?.questions?.findIndex((el) => el.id === currentQuestion.id);
  const maxIndex = currentStep?.questions?.length - 1;
  const hasNextQuestion = maxIndex > questionIndex;
  const isNotDev = projectEnvironment !== 'development';
  const accountId = user?.academyAccount?.id;

  const handleMarkAnswer = (event, answer) => {
    setMarkedAnswer(answer);
    quizResultsRef.current[currentQuestion.id] = answer.id;

    event.target.disabled = true;
    const { classList } = event?.target ?? {};
    classList.add('marked');

    setTimeout(() => classList.add(answer?.isCorrect ? 'correct' : 'incorrect'), isNotDev ? 1000 : 0);
    setTimeout(() => handleNextQuestion(), isNotDev ? 3500 : 500);
  };

  const handleNextQuestion = () => {
    setMarkedAnswer(null);

    if (!hasNextQuestion) return handleSubmit();

    setCurrentQuestion(currentStep?.questions?.at(questionIndex + 1));
  };

  const handleSubmit = async () => {
    // If the quiz is already taken and there are results show the results saved into db
    // The current take can have totally different result, but it is ok
    if (quizResultsPercentage) return onNextStep(quizResultsPercentage);

    const questions = Object.entries(quizResultsRef.current).map(([questionId, answerId]) => ({
      id: Number(questionId),
      answerIds: [answerId],
    }));

    const body = {
      accountId,
      accountCourseId: course?.accountCourse?.id,
      quiz: { id: currentStep?.id, questions },
    };

    const [res, err] = await postNewQuizHistory(body);
    if (err) return showApiError(err);

    initAchievementsStore(accountId);
    refreshQuizResults();
    onNextStep(res?.scorePercentage);
  };

  return (
    <div css={courseQuizContainer}>
      <span>
        {questionIndex + 1} / {currentStep?.questions?.length}
      </span>
      <h2 className="quiz-question">{getText(currentQuestion?.title)}</h2>
      {isArray(currentQuestion?.answers) &&
        currentQuestion.answers.map((answer) => (
          <button
            key={answer?.id}
            className="quiz-answer"
            onClick={(e) => handleMarkAnswer(e, answer)}
            disabled={!!markedAnswer}>
            {getText(answer?.text)}
            {!isNotDev && answer?.isCorrect && <span css={{ marginLeft: '1em', color: 'green' }}>(CORRECT)</span>}
          </button>
        ))}
    </div>
  );
};

QuizViewer.propTypes = {
  course: PropTypes.object,
  currentStep: PropTypes.object,
  user: PropTypes.object,
  onNextStep: PropTypes.func,
  quizResultsPercentage: PropTypes.number,
  refreshQuizResults: PropTypes.func,
};
