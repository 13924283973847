export const dropDownContainer = (disabled) => ({
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
  opacity: disabled ? 0.3 : 1,
  outline: 'none',
  fontSize: 12,
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const dropDownSelect = (isOpened, isSelected) => (theme) => ({
  border: `1px solid ${isOpened ? theme.primary : theme.gray3}`,
  borderRadius: isOpened ? '4px 4px 0 0' : '4px',
  boxShadow: isOpened ? '0 1px 4px 0 rgba(162, 163, 166, 0.4)' : 'none',
  backgroundColor: isOpened || isSelected ? theme.white : theme.gray3,
  padding: 6,
  color: isSelected ? theme.gray1 : theme.gray3,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const dropDownText = (isSelected) => ({
  userSelect: isSelected ? 'text' : 'none',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '100%',
});

export const dropDownOptionsContainer = (onTop, onLeft) => (theme) =>
  [
    {
      position: 'absolute',
      zIndex: 150,
      color: theme.disabled,
      height: 'auto',
      maxHeight: 300,
      overflowY: 'auto',
      backgroundColor: theme.white,
      cursor: 'default',
      width: '100%',
      transform: 'scaleY(0)',

      '&.opened': {
        transition: 'transform 400ms ease-in-out',
        transform: 'scaleY(1)',
        transformOrigin: onTop ? 'bottom' : 'top',
      },
    },
    onTop
      ? {
          bottom: '100%',
          boxShadow: '0 -2px 4px rgba(0,0,0,0.3)',
          borderBottom: '1px solid #DDDDDD',
          borderRadius: 8,
        }
      : {
          top: '100%',
          boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
          borderTop: '1px solid #DDDDDD',
          borderRadius: 8,
        },
    onLeft && { right: 0 },
  ];

export const dropDownOptionItem = (isSelected, hasRender) => (theme) =>
  [
    {
      backgroundColor: isSelected ? theme.primary : theme.white,
      color: isSelected ? theme.white : '',
      cursor: 'pointer',
      width: '100%',
      fontWeight: 400,

      '&:hover': {
        backgroundColor: isSelected ? theme.primaryLighter2 : theme.gray3,
      },
      '&:focus': {
        outline: 'none',
      },
      '& i': {
        marginRight: 8,
      },
    },
    !hasRender && {
      padding: '6px 12px',
    },
  ];

export const dropDownGroupLabel = (theme) => ({
  margin: 0,
  padding: 12,
  cursor: 'default',
  color: theme.disabled,
  fontSize: 12,
  fontWeight: 400,
});

export const rightIconsContainer = {
  display: 'flex',
};

export const dropDownRightIcon = (isOpened) => ({
  fontSize: '19px',
  transform: `rotate(${isOpened ? -180 : 0}deg)`,
  transition: 'transform 0.35s ease-out',
  cursor: 'pointer',
  display: 'inline-flex',
});

export const dropDownDeleteIcon = (theme) => ({
  display: 'inline-flex',
  fontSize: '19px',
  cursor: 'pointer',
  color: theme.disabled,

  '&:hover': {
    color: theme.disabled,
  },
});

export const dropdownSearch = (theme) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.white,
  borderBottom: `1px solid ${theme.gray3}`,
});

export const noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px 8px',
  '& h4': {
    margin: '8px 0 12px 0',
  },
};

export const noResultsIcon = (theme) => ({
  backgroundColor: theme.info,
  borderRadius: '50%',
  padding: 8,
});

export const infiniteScroll = {
  padding: 16,
  display: 'flex',
  justifyContent: 'center',
};
