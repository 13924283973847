import { useState, useEffect } from 'react';
import { useUrlParams, useDeepEffect } from 'hooks';
import { useCourses } from 'stores';

export const useAllCoursesList = (getText) => {
  const { queryParams, setQueryParams } = useUrlParams();
  const allCourses = useCourses();
  const [courses, setCourses] = useState(null);
  const [activeCategory, setActiveCategory] = useState(queryParams?.categoryId ?? 0);
  const [searchText, setSearchText] = useState(queryParams?.search ?? '');

  useDeepEffect(() => {
    allCourses?.length && filterCourses(activeCategory, searchText);
  }, [allCourses]);

  useEffect(() => {
    handleSearchChange(queryParams?.search);
  }, [queryParams?.search]);

  const filterCourses = (categoryId, searchName) => {
    let filteredCourses = allCourses?.filter((course) => !categoryId || course?.category?.id === categoryId);

    if (searchName?.length) {
      filteredCourses = filteredCourses?.filter((course) =>
        getText(course?.name)?.trim()?.toLowerCase()?.includes(searchName?.trim()?.toLowerCase()),
      );
    }

    setCourses(filteredCourses);
  };

  const handleCategorySelect = (categoryId) => {
    setActiveCategory(categoryId);
    filterCourses(categoryId, searchText);
    setQueryParams({ categoryId });
  };

  const handleSearchChange = (newSearchText) => {
    setSearchText(newSearchText);
    filterCourses(activeCategory, newSearchText);
    setQueryParams({ search: newSearchText });
  };

  return {
    courses,
    searchText,
    activeCategory,
    handleCategorySelect,
    handleSearchChange,
  };
};
