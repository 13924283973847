export const wrapper = (theme) => ({
  width: '100%',
  color: theme.secondaryLighter,
  '& .bottomBuffer': {
    marginBottom: 24,
  },
});

export const tabContainer = {
  padding: 40,
};

export const skeletonStyle = {
  marginBottom: 40,
};

export const categoryAndCardWrapper = (theme) => ({
  backgroundColor: theme.white,
  margin: 0,
  paddingBottom: '2em',
  '@media (min-width: 768px)': {
    maxWidth: '100%',
  },
  '@media (max-width: 450px)': {
    '.categories-sidebar': {
      display: 'none',
    },
  },
});

export const sideBarStyle = (theme) => ({
  backgroundColor: theme.white,
  color: theme.gray4,
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.875rem',
  '& .category-item': {
    padding: '1em 1.25em',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    color: theme.gray4,
    borderBottom: '1px solid #0000001F',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'capitalize',
    '& img': {
      width: 36,
      height: 36,
      padding: 4,
      borderRadius: '50%',
      background: theme.white,
    },
    '& span': {
      marginRight: '0.5em',
      fontSize: '20px',
    },
    '&:hover': {
      backgroundColor: theme.purpleLight,
    },
    '&[data-active="true"]': {
      backgroundColor: theme.purpleDark,
      color: theme.white,
      borderBottom: `1px solid ${theme.purpleDark}`,
    },
  },
});

export const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.25em',
};

export const pickCategory = (theme) => ({
  color: theme.primary,
  justifyContent: 'space-between',
  paddingRight: '2em',
  '@media (max-width: 450px)': {
    gap: '1em',
    padding: '2em',
    '& h4': {
      marginBottom: '0.5em',
    },
  },
});

export const customColCategoryCard = {
  flex: '3 0 0',
  padding: '0 2em',
};

export const dropdownCategory = (theme) => ({
  display: 'none',

  '& > div': {
    border: `1px solid ${theme.gray3}`,
    borderRadius: 36,
    padding: '0.375em 0.75em',
    fontWeight: 400,
    fontFamily: 'Roboto, sans-serif;',
    fontSize: '0.875rem',
  },
  '& .opened': {
    width: '100%',
    borderRadius: 8,
    padding: 0,
  },
  '@media (max-width: 450px)': {
    display: 'block',
    width: '250px',
  },
});

export const categoryIconStyle = {
  width: '32px',
  height: '32px',
  marginRight: '0.5em',
};
