import { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isString } from 'lodash-es';
import OptionsList from './OptionsList';
import Icon from '../Icon';
import { useDropDown } from './useDropDown';
import { prepareOptions } from './utils';
import {
  dropDownContainer,
  dropDownSelect,
  dropDownOptionsContainer,
  dropDownRightIcon,
  dropDownText,
  rightIconsContainer,
} from './styles';

const Dropdown = forwardRef((props, ref) => {
  const {
    rightIcon,
    multiSelect,
    onTop,
    onLeft,
    options: initOptions = [],
    disabled: initDisabled,
    className,
    withSearch,
    displayKey = 'label',
    uniqueKey = 'value',
  } = props;

  const options = prepareOptions(initOptions, displayKey);
  const disabled = !options || !options.length || initDisabled;

  const {
    openClose,
    handleBlur,
    handleSelect,
    handleClear,
    isOpen,
    selected,
    displayValue,
    selectRef,
    calculateDisplayValue,
  } = useDropDown({ ...props, options });

  useImperativeHandle(ref, () => ({
    changeValue: handleSelect,
    value: selected,
    displayValue,
    clear: handleClear,
  }));

  const isSelected = multiSelect ? selected.length : !isEmpty(selected);

  return (
    <div
      role="menuitem"
      tabIndex={0}
      onBlur={handleBlur}
      {...(isString(className) && { className })}
      css={dropDownContainer(disabled)}
      onClick={openClose}>
      <div css={dropDownSelect(isOpen, isSelected)}>
        <span css={dropDownText(isSelected)} title={String(selected[uniqueKey]) || undefined}>
          {calculateDisplayValue()}
        </span>
        <div css={rightIconsContainer}>
          <Icon
            material
            iconName="keyboard_arrow_down"
            color="disabled"
            css={dropDownRightIcon(isOpen)}
            {...rightIcon}
          />
        </div>
      </div>
      <OptionsList
        isOpen={isOpen}
        displayKey={displayKey}
        uniqueKey={uniqueKey}
        withSearch={withSearch}
        multiSelect={multiSelect}
        options={options}
        selected={selected}
        onSelect={handleSelect}
        css={dropDownOptionsContainer(onTop, onLeft)}
        containerRef={selectRef}
      />
    </div>
  );
});

Dropdown.propTypes = {
  rightIcon: PropTypes.object,
  multiSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  mapValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string, PropTypes.array]),
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        values: PropTypes.array,
      }),
    ),
  ]),
  onTop: PropTypes.bool,
  onLeft: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withSearch: PropTypes.bool,
  noSimplify: PropTypes.bool,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string,
};

export default Dropdown;
