export const tableContainer = (theme) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.white,
});

export const containerBody = (theme) => ({
  marginBottom: 15,
  border: `1px solid ${theme.gray3}`,
});

export const tableHead = {
  display: 'flex',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
};

export const tableRow = (theme) => ({
  display: 'flex',
  alignItems: 'stretch',
  color: theme.gray2,

  '&:hover': {
    backgroundColor: theme.gray3,

    '& i': {
      color: theme.disabled,
    },
  },

  '& i': {
    color: theme.disabled,
  },

  '&:nth-of-type(2n - 1)': {
    backgroundColor: theme.gray3,
    '&:hover': {
      backgroundColor: theme.gray3,

      '& i': {
        color: theme.disabled,
      },
    },
  },
});

export const tableCell = (width, hasRender) => ({
  fontSize: '1rem',
  lineHeight: '1.46',
  letterSpacing: '0.35px',
  flex: width ? `0 1 ${width}px` : '1 0 0%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: hasRender ? '0 6px' : '16px 6px',
  outline: 'none',
  cursor: 'default',
  wordBreak: 'break-word',
});

export const tableHeadCell = ({ sortable, width }) => [
  tableCell(width),
  {
    fontSize: 12,
    fontWeight: 600,
    padding: 6,
  },
  sortable && {
    cursor: 'default',
    '& b': {
      cursor: 'pointer',
      '&:hover + i': {
        opacity: 1,
      },
    },
  },
];

export const tableHeadIcon = (isAsc, isDesc) => ({
  fontSize: 18,
  opacity: isAsc || isDesc ? 1 : 0,
  transform: `rotate(${isDesc ? 0 : 180}deg)`,
  transition: 'transform 300ms',
});

export const tableCellContent = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '& span': {
    display: 'inline',
  },
};

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
};

export const shownResultsIndicator = (theme) => ({
  color: theme.disabled,
  width: 80,
  textAlign: 'center',
  fontSize: 12,
});

export const paginationButton = (disabled) => (theme) =>
  [
    {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      userSelect: 'none',

      '&:first-of-type': {
        marginRight: 32,
      },
    },
    disabled && {
      cursor: 'not-allowed',
      color: theme.disabled,
    },
  ];

export const noResultsContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '80px 20px',
  textAlign: 'center',
  flexDirection: 'column',
};

export const noResultsIcon = (theme) => ({
  width: 80,
  height: 80,
  backgroundColor: theme.primaryLighter2,
  marginBottom: 16,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const noResultsTitle = {
  fontWeight: 500,
  marginBottom: 12,
};

export const noResultsSubtitle = (theme) => ({
  color: theme.disabled,
});
