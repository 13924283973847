import { getReq } from './axios/makeRequest';
import { getAllCoursesParams, getAllCourseMaterialParams, getMyCoursesParams } from './models/courses';

const baseUrl = `${apiUrls.oneAcademy}/courses`;

export const getAllCourses = (options = {}) => getReq(`${baseUrl}/list?${getAllCoursesParams(options)}`);

export const getMyCourses = (options = {}) => getReq(`${baseUrl}/my?${getMyCoursesParams(options)}`);

export const getCourseById = (id, accountId) => getReq(`${baseUrl}/${id}?accountId=${accountId}`);

export const getCourseMaterial = (courseId, options = {}) =>
  getReq(`${baseUrl}/${courseId}/materials?${getAllCourseMaterialParams(options)}`);
