import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { PdfLoader } from '../AdditionalElements';
import { pdfViewerContainer, pdfViewerControls, navButtons } from './styles';

export const PdfViewer = ({ currentStep }) => {
  const { getText, language } = useTranslations();
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const containerRef = useRef();

  const matchTranslation = currentStep?.translations?.find((el) => el.languageCode === language?.code);
  const filePath = matchTranslation?.sourceUrl ?? currentStep?.sourceUrl;

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [numPages]);

  const onDocumentLoadSuccess = (options) => setNumPages(options?.numPages);

  const goToPreviousPage = () => setPageNumber((prev) => (prev > 2 ? prev - 2 : prev));

  const goToNextPage = () => setPageNumber((prev) => (prev < numPages - 1 ? prev + 2 : prev));

  const handleKeyDown = (event) => {
    event.keyCode === 27 && exitFullScreen();
    event.keyCode === 37 && goToPreviousPage();
    event.keyCode === 39 && goToNextPage();
  };

  const goFullScreen = () => {
    containerRef.current?.setAttribute('data-fullscreen', 'true');
    setFullScreen(true);
  };

  const exitFullScreen = () => {
    containerRef.current?.setAttribute('data-fullscreen', 'false');
    setFullScreen(false);
  };

  const height = containerRef?.current?.clientHeight;
  const canvasScale = (height ? height - 70 : 1500) / 1500;

  return (
    <div ref={containerRef} css={pdfViewerContainer}>
      <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess} loading={PdfLoader} className="pdf-document">
        <Page pageNumber={pageNumber} height="1500" className="pdf-page" scale={canvasScale} />
        {pageNumber > 1 && pageNumber < numPages && (
          <Page pageNumber={pageNumber + 1} height="1500" className="pdf-page" scale={canvasScale} />
        )}
      </Document>
      {numPages && (
        <div css={pdfViewerControls}>
          <div className="controls-container">
            <Button clear css={navButtons} onClick={goToPreviousPage} disabled={pageNumber <= 1}>
              <i className="fas fa-chevron-left" />
            </Button>
            <p>{`${getText('page')} ${pageNumber} ${getText('of')} ${numPages}`}</p>
            <Button clear css={navButtons} onClick={goToNextPage} disabled={pageNumber >= numPages}>
              <i className="fas fa-chevron-right" />
            </Button>
            <Button clear css={navButtons} onClick={fullScreen ? exitFullScreen : goFullScreen}>
              <i className="fas fa-arrows-alt" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

PdfViewer.propTypes = {
  currentStep: PropTypes.object,
};
