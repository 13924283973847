import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Tabs, Tab } from 'components';
import MaterialsList from './MaterialsList';
import { wrapper, tabContainer, aboutTab } from './styles';
import CertificateTab from './CertificateTab';

const InfoTabs = (props) => {
  const { course, quizResultsPercentage } = props;
  const { getText } = useTranslations();

  const aboutContent = () => (
    <div css={aboutTab}>
      <h6>{getText('courseOverview')}</h6>
      {course?.summary ? <p>{getText(course?.summary)}</p> : <p>{getText('noSummary')}</p>}
    </div>
  );

  return (
    <section css={wrapper}>
      <Tabs routeMatch="/course/:id" startingRoute={`/course/${course?.id}`} tabsContentClass={tabContainer}>
        <Tab label={getText('overview')} url="/about" component={() => aboutContent()} />
        <Tab label={getText('materials')} url="/resources" component={() => <MaterialsList course={course} />} />
        <Tab
          label={getText('certificate')}
          url="/certificate"
          component={() => <CertificateTab course={course} quizResultsPercentage={quizResultsPercentage} />}
        />
      </Tabs>
    </section>
  );
};

InfoTabs.propTypes = {
  course: PropTypes.object,
  quizResultsPercentage: PropTypes.number,
};

export default InfoTabs;
