import { isNil, isString, isNumber, isArray, isPlainObject, isEmpty, isNull } from 'lodash-es';

export const noValue = (val) => isNil(val) || ((isString(val) || isArray(val) || isPlainObject(val)) && isEmpty(val));

export const isStrNum = (val) => isString(val) || isNumber(val);

export const validDate = (date) => (date === '0001-01-01T00:00:00' ? null : date);

export const deepEqual = (a, b) => {
  if (a === b) return true;

  if (typeof a === 'function' && b.toString) return a.toString() === b.toString();

  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) return false;

    if (Array.isArray(a)) {
      if (a.length !== b.length) return false;
      for (let i = a.length - 1; i >= 0; i--) if (!deepEqual(a[i], b[i])) return false;
      return true;
    }

    if (a instanceof Map && b instanceof Map) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      for (const i of a.entries()) if (!deepEqual(i[1], b.get(i[0]))) return false;
      return true;
    }

    if (a instanceof Set && b instanceof Set) {
      if (a.size !== b.size) return false;
      for (const i of a.entries()) if (!b.has(i[0])) return false;
      return true;
    }

    if (ArrayBuffer.isView(a) && ArrayBuffer.isView(b)) {
      if (a.length !== b.length) return false;
      for (let i = a.length - 1; i >= 0; i--) if (a[i] !== b[i]) return false;
      return true;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;

    for (let i = keys.length - 1; i >= 0; i--) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i];

      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  return false;
};

export const isJsonString = (text) => {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const jsonParse = (text, defaultValue = null) => {
  if (typeof text !== 'string') return defaultValue;

  try {
    return JSON.parse(text);
  } catch (error) {
    return defaultValue;
  }
};

export const formatShortText = (val, textLength) => {
  if (isNull(val) || val === '' || val === undefined) return '';
  const formatedText = val.replace(/(<([^>]+)>)/gi, '');
  const textLengthVal = textLength ?? 150;
  return formatedText.length > textLengthVal ? `${formatedText.substring(0, textLengthVal)}...` : formatedText;
};
