import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Row, Col, MightLikeCard, Skeleton } from 'components';
import { useCourses, getRandomCourses } from 'stores';
import { mightLikeWrapper } from './styles';

export const MightLikeCourses = () => {
  useCourses();
  const { getText } = useTranslations();

  const courses = getRandomCourses();

  return (
    <div css={mightLikeWrapper}>
      <Row container>
        <Col xs={12}>
          <h2 className="section-title">{getText('coursesMightLike')}</h2>
        </Col>
      </Row>
      <Row horizontalGap={12} noWrap container>
        {!isNil(courses)
          ? courses?.map((el) => (
              <Col xs={12} sm={6} md={4} lg={2.4} key={el.id} className="bottomBuffer">
                <MightLikeCard course={el} />
              </Col>
            ))
          : Array(5)
              .fill()
              .map((_, ind) => (
                <Col xs={12} sm={6} md={4} lg={2.4} key={ind} className="bottomBuffer">
                  <Skeleton width="100%" height="300px" />
                </Col>
              ))}
      </Row>
    </div>
  );
};
