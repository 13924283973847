import { useState } from 'react';
import { isNull, isEmpty } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { MightLikeCourses } from 'components';
import { Row, Col, Skeleton, Dropdown, GroupedCoursesList } from 'components';
import { useMyCourses } from 'stores';
import noDataBgImage from 'assets/images/myCoursesNoData.svg';
import {
  heading,
  bigHeading,
  noData,
  myCoursesHeaderWrap,
  filterSearchDropdown,
  filterAndHeadingWrapper,
} from './styles';

const MyCourses = () => {
  const { getText } = useTranslations();
  const myCourses = useMyCourses();
  const [filterValue, setFilterValue] = useState(null);

  const filteredCourses = myCourses?.filter((el) => !filterValue || el[filterValue]);

  const filterOptions = [
    { label: getText('all'), value: null },
    { label: getText('completed'), value: 'isCompleted' },
    { label: getText('inProgress'), value: 'inProgress' },
  ];

  const handleSortChange = ({ value }) => setFilterValue(value);

  return (
    <>
      <section className="header">
        <Row horizontalGap={12} noWrap container css={myCoursesHeaderWrap}>
          <Col>
            <h2 css={heading}>{getText('courses')}</h2>
          </Col>
          <Row horizontalGap={12} noWrap container>
            <Col>
              <div css={bigHeading}>
                <h3>{getText('myCourses')}</h3>
              </div>
            </Col>
          </Row>
        </Row>
      </section>
      <Row container css={filterAndHeadingWrapper}>
        <Col>
          <h2 className="section-title">{`${getText('youHave')} ${filteredCourses?.length} ${
            filteredCourses?.length === 1 ? getText('course') : getText('courses')
          }`}</h2>
        </Col>
        <Dropdown
          options={filterOptions}
          autoWidth
          value={filterOptions[0]}
          css={filterSearchDropdown}
          onChange={handleSortChange}
        />
      </Row>

      {!isNull(myCourses) ? (
        !isEmpty(filteredCourses) ? (
          <GroupedCoursesList courses={filteredCourses} />
        ) : (
          <div css={noData}>
            <img src={noDataBgImage} alt="" />
          </div>
        )
      ) : (
        <Row horizontalGap={12} noWrap container>
          {Array(5)
            .fill()
            .map((_, index) => (
              <Col xs={12} sm={6} md={4} lg={2.4} key={index} className="bottomBuffer">
                <Skeleton height="340px" width="100%" />
              </Col>
            ))}
        </Row>
      )}

      <Row>
        <MightLikeCourses />
      </Row>
    </>
  );
};

export default MyCourses;
