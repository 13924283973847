import queryString from 'query-string';
import { pick } from 'lodash-es';

export const getAllCategoriesParams = (options) => {
  const defaultSort = {
    sortBy: 'id',
    sortDirection: 'asc',
  };

  const mapSort = (sort) => ({
    sortBy: sort.sortField,
    sortDirection: sort.sortOrder,
  });

  return queryString.stringify(
    {
      pageIndex: 0,
      pageSize: 100,
      ...pick(options, ['pageIndex', 'pageSize']),
      ...(options.sort ? mapSort(options.sort) : defaultSort),
    },
    { arrayFormat: 'index' },
  );
};
