import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { quizResults } from './styles';

const getPoints = (percentage) => {
  switch (true) {
    case percentage >= 51 && percentage <= 65:
      return 1;
    case percentage >= 66 && percentage <= 80:
      return 3;
    case percentage >= 81 && percentage <= 90:
      return 6;
    case percentage >= 91:
      return 10;
    default:
      return 0;
  }
};

export const QuizResults = ({ quizResultsPercentage, goToQuiz }) => {
  const { getText } = useTranslations();
  const points = getPoints(quizResultsPercentage);

  return (
    <div css={quizResults}>
      <img
        src={`https://oneecosystem.blob.core.windows.net/academy/icons/quiz-results/points-${points}.png`}
        alt="score"
        className="quiz-results-image"
      />
      <h2>{getText('quizCompleted')}</h2>
      <p>{getText('yourQuizResults', { points, quizResultsPercentage: quizResultsPercentage })}</p>
      <Button outline onClick={goToQuiz} className="retake-button">
        {getText('retake')}
      </Button>
    </div>
  );
};

QuizResults.propTypes = {
  quizResultsPercentage: PropTypes.number,
  goToQuiz: PropTypes.func,
};
